<template>
  <div v-if="$parent.actualRoom" :class="{ 'is-manager': $parent.isManager }" class="live-conversation"
    @click="markAsRead">
    <!-- <div class="testing-container">
        <p class="d-none">Message type: <span class="text-danger">{{currentMessages}}</span></p>
        <p>allowSend: <span class="text-danger">{{allowSend}}</span></p>
      </div> -->
    <header class="live-inner-header">
      <!--
        {{readOnly ? 'leitura' : 'atendimento' }}:
        ({{$parent.oldRoom ? $parent.oldRoom.roomId : '-'}})
      -->
      <button v-if="$parent.isManager" class="live-back" @click="exitRoom()">
        <!-- v-if="$parent.isManager" -->
        <span class="material-icons-outlined">arrow_back_ios</span>
      </button>
      <div v-if="false" :class="{ 'mx-2': !$parent.isManager }" class="live-photo">
        <div class="live-channel">
          <i v-if="currentChannel === 'widget'" class="fas fa-globe"></i>
          <i v-else-if="currentChannel === 'widget-java'" class="fas fa-globe"></i>
          <i v-else-if="currentChannel === 'messenger'" class="fab fa-facebook-messenger"></i>
          <i v-else-if="currentChannel === 'whatsapp'" class="fab fa-whatsapp"></i>
          <i v-else-if="currentChannel === 'whatsapp-java'" class="fab fa-whatsapp"></i>
          <i v-else-if="currentChannel === 'whatsapp-web'" class="fab fa-whatsapp"></i>
          <i v-else-if="currentChannel === 'rcs'" class="fab fa-google"></i>
          <i v-else-if="currentChannel === 'rcs-google'" class="fab fa-google"></i>
          <i v-else-if="currentChannel === 'telegram'" class="fab fa-telegram"></i>
          <i v-else-if="currentChannel === 'instagram'" class="fab fa-instagram"></i>
          <i v-else-if="currentChannel === 'email'" class="fas fa-envelope"></i>
          <i v-else-if="currentChannel === 'google-business'" class="fab fa-google"></i>
          <i v-else class="fas fa-question"></i>
        </div>
        <button>
          <img v-if="$live.profilePics[$parent.actualRoom.roomId]"
            :src="$live.profilePics[$parent.actualRoom.roomId]" />
          <span class="material-icons-outlined">person</span>
        </button>
      </div>
      <div class="live-header-info" @click="$parent.metaShow = true">
        <div class="live-header-name">
          <span class="sub">
            {{ $parent.currentName }}
          </span>
          <span v-if="$parent.actualRoom.status === 'OPEN'" class="live-status badge badge-success">
            Ativo
          </span>
          <!-- <span class="live-status global" v-else-if="$parent.currentRoom.parentRoomId.indexOf('global') !== -1">Finalizado</span> -->
          <span v-else-if="$parent.actualRoom.localStatus === 'queue'"
            class="live-status badge badge-danger">Fila</span>
          <span v-else-if="$parent.actualRoom.localStatus === 'transfer'"
            class="live-status badge badge-danger">Transferido</span>
          <span v-else-if="$parent.actualRoom.status === 'CLOSED'"
            class="live-status badge badge-danger">Finalizado</span>
          <span v-else-if="
            $parent.actualRoom && $parent.actualRoom.parentRoomId.indexOf('offline') !== -1
          " class="live-status badge badge-info">
            Pendente
          </span>
        </div>
        <div v-if="$parent.actualRoom.contact.email" ref="contactEmail"
          class="d-flex align-items-center live-header-contact">
          <span class="material-icons-outlined mr-2">email</span>
          <div class="flex-grow-1">
            {{ $parent.actualRoom.contact.email }}
          </div>
        </div>
        <div v-if="$parent.actualRoom.contact.mobileNumber" ref="contactMobileNumber"
          class="d-flex align-items-center live-header-contact">
          <span class="material-icons-outlined mr-2">call</span>
          <div class="flex-grow-1">
            {{ $live.maskPhone($parent.actualRoom.contact.mobileNumber) }}
          </div>
        </div>
        <div v-if="$parent.actualRoom.contact.phone" ref="contactPhone"
          class="d-flex align-items-center live-header-contact">
          <span class="material-icons-outlined mr-2">phone</span>
          <div class="flex-grow-1">
            {{ $live.maskPhone($parent.actualRoom.contact.phone) }}
          </div>
        </div>
      </div>

      <div v-if="$parent.actualRoom.locked" class="live-header-tools --d-flex">
        <div class="badge badge-warning">transferindo</div>
      </div>
      <div v-else class="live-header-tools --d-flex">
        <template v-if="!readOnly">
          <div class="menu-item">
            <b-dropdown :disabled="filePreviewShowed" right>
              <template #button-content>
                <span class="material-icons-outlined">sync_alt</span>
              </template>
              <b-dropdown-item @click="$parent.showAgents">
                Transferir para outro agente
              </b-dropdown-item>
              <b-dropdown-item @click="$parent.showDepartments">
                Transferir para departamento
              </b-dropdown-item>
              <b-dropdown-item @click="$parent.transferToQueue">
                Devolver para fila
              </b-dropdown-item>
              <!-- <b-dropdown-item>Devolver para a fila</b-dropdown-item> -->
            </b-dropdown>
          </div>
          <!-- <b-button id="tip-reply" @click="$parent.showAgents()">
            <i class="fas fa-reply-all"></i>
          </b-button> -->
          <!-- <b-button id="tip-user">
            <i class="fas fa-user"></i>
          </b-button> -->
        </template>

        <b-button id="tip-print" :disabled="filePreviewShowed" @click="$parent.print()">
          <span class="material-icons-outlined">print</span>
        </b-button>

        <!-- <template v-if="!readOnly && !$parent.isManager"> -->
        <template v-if="!readOnly">
          <!-- <b-button id="tip-signout" @click="$parent.finish()" :disabled="filePreviewShowed">
            <span class="material-icons-outlined">logout</span>
          </b-button> -->
          <button id="tip-signout-btn" :disabled="filePreviewShowed || this.$live.tabsLoading"
            class="btn btn-primary btn-common" @click="$parent.finish()">
            Finalizar
          </button>
        </template>

        <b-button id="tip-meta" :disabled="filePreviewShowed" class="toggle-meta" @click="$parent.toggleMeta()">
          <i class="fas fa-columns"></i>
        </b-button>

        <!-- tooltips -->
        <b-tooltip placement="bottom" target="tip-print">
          {{ $t('generic-str.printout') }}
        </b-tooltip>

        <template v-if="!readOnly">
          <b-tooltip placement="bottom" target="tip-signout">
            {{ $t('live.end-att') }}
          </b-tooltip>
          <!-- <b-tooltip target="tip-reply" placement="bottom">
            Transferir atendimento
          </b-tooltip> -->
          <!-- <b-tooltip target="tip-user" placement="bottom">
            Foo bar
          </b-tooltip> -->
        </template>

        <b-tooltip placement="bottom" target="tip-meta">
          {{ $t('live.show-info') }}
        </b-tooltip>
      </div>

      <b-dropdown class="live-header-tools-m" right>
        <template #button-content>
          <i class="fas fa-ellipsis-v"></i>
        </template>
        <b-dropdown-item-button @click="$parent.print()">
          <i class="fas fa-print"></i>
          {{ $t('generic-str.printout') }}
        </b-dropdown-item-button>
        <!-- <b-dropdown-item-button @click="$parent.showAgents" v-if="!readOnly && !$parent.isManager"> -->
        <b-dropdown-item-button v-if="!readOnly" @click="$parent.showAgents">
          <i class="fas fa-reply-all"></i>
          {{ $t('live.transfer-agnt') }}
        </b-dropdown-item-button>
        <!-- <b-dropdown-item-button @click="$parent.showDepartments" v-if="!readOnly && !$parent.isManager"> -->
        <b-dropdown-item-button v-if="!readOnly" @click="$parent.showDepartments">
          <i class="fas fa-reply-all"></i>
          {{ $t('live.transfer-dep2') }}
        </b-dropdown-item-button>
        <!-- <b-dropdown-item-button @click="$parent.transferToQueue" v-if="!readOnly && !$parent.isManager"> -->
        <b-dropdown-item-button v-if="!readOnly" @click="$parent.transferToQueue">
          <i class="fas fa-reply-all"></i>
          Devolver para fila
        </b-dropdown-item-button>
        <b-dropdown-item-button @click="$parent.toggleMeta()">
          <i class="fas fa-columns"></i>
          {{ $t('live.show-info') }}
        </b-dropdown-item-button>

        <b-dropdown-divider v-if="!readOnly"></b-dropdown-divider>

        <b-dropdown-item-button v-if="!readOnly" @click="$parent.finish()">
          <i class="fas fa-sign-out-alt"></i>
          {{ $t('live.end-att') }}
        </b-dropdown-item-button>
      </b-dropdown>
    </header>
    <aside v-if="$parent.oldRoom !== null" class="live-history-header">
      {{ $t('live.protocol-history') }}
      <strong>{{ $parent.oldRoom.protocol }}</strong>
      <button @click="$parent.closeHistory()">voltar</button>
    </aside>
    <!-- roomId >> {{$parent.oldRoom.roomId}} {{$live.associativeTranscriptsMessages}} -->
    <template v-if="$live.loadingMessages">
      <div class="qt-loader qt-loader-mini text-center">
        <b-spinner small type="grow" variant="primary"></b-spinner>
        <b-spinner small type="grow" variant="primary"></b-spinner>
        <b-spinner small type="grow" variant="primary"></b-spinner>
      </div>
    </template>
    <template v-else>
      <div ref="transcript" :class="{ 'live-transcript-old': $parent.oldRoom, readonly: readOnly && !$parent.oldRoom }"
        class="live-transcript">
        <!-- <pre>{{$parent.fromTranscript}}:{{($parent.fromTranscript ? $live.associativeTranscriptsMessages : $live.messages)}}</pre> -->

        <!-- antigas -->
        <template v-for="(transcript, transcriptIndex) in associativeTranscriptsHistory">
          <!--  v-if="$live.associativeTranscriptsMessages[transcript.roomId]" -->
          <div v-if="
            currentRoomId &&
            removeRoomPrefix(currentRoomId) !== transcript.roomId &&
            transcript.parentRoomId.indexOf(removeRoomPrefix($parent.actualRoom.roomId)) !== -1
          " :key="transcriptIndex" class="omni-transcript">
            <!-- <pre>{{transcript.roomId}}</pre> -->
            <header v-if="hasTranscriptMessages(transcript.roomId)" class="omni-transcript-header">
              <div class="card">
                <i v-if="transcript.channel === 'widget'" class="fas fa-globe"></i>
                <i v-else-if="transcript.channel === 'widget-java'" class="fas fa-globe"></i>
                <i v-else-if="transcript.channel === 'messenger'" class="fab fa-facebook-messenger"></i>
                <i v-else-if="transcript.channel === 'whatsapp'" class="fab fa-whatsapp"></i>
                <i v-else-if="transcript.channel === 'whatsapp-java'" class="fab fa-whatsapp"></i>
                <i v-else-if="transcript.channel === 'whatsapp-web'" class="fab fa-whatsapp"></i>
                <i v-else-if="transcript.channel === 'rcs'" class="fab fa-google"></i>
                <i v-else-if="transcript.channel === 'rcs-google'" class="fab fa-google"></i>
                <i v-else-if="transcript.channel === 'telegram'" class="fab fa-telegram"></i>
                <i v-else-if="transcript.channel === 'instagram'" class="fab fa-instagram"></i>
                <i v-else-if="transcript.channel === 'email'" class="fas fa-envelope"></i>
                <i v-else-if="transcript.channel === 'google-business'" class="fab fa-google"></i>
                <i v-else class="fas fa-question"></i>
                {{ transcript.createdAt | onlyDate }}
                <span class="dot"></span>
                {{ transcript.protocol }}
                <span class="dot"></span>
                {{ transcript.contact.firstName }}
                {{ transcript.contact.lastName }}
              </div>
            </header>
            <template v-for="message in $live.associativeTranscriptsMessages">
              <div v-if="message.roomId === transcript.roomId" :key="`message_${message.id}:${transcript.roomId}`"
                :class="{
                  me: message.me,
                  automatic: message.automatic,
                  deleted: message.status === 'DELETED',
                  fail: message.status === 'FAIL',
                  'another-agent': message.typeUser === 'AGENT' && !message.me,
                  'place-offline': message.offline,
                }" class="live-message">
                <div class="live-message-box">
                  <div class="live-message-inner">
                    <picture v-if="
                      !message.automatic &&
                      message.typeUser !== 'AGENT' &&
                      !message.me &&
                      message.picture &&
                      $live.profilePics[$parent.currentRoom.roomId]
                    ">
                      <img :src="message.picture || $live.profilePics[$parent.currentRoom.roomId]" />
                    </picture>
                    <span v-if="message.automatic" class="badge bg-warning live-agent">{{
                      message.typeUser === 'BOT' ? 'BOT' : 'Automática'
                    }}</span>
                    <span v-else-if="message.typeUser === 'AGENT' && !message.me"
                      class="badge bg-primary live-agent">Agente</span>
                    <span v-else-if="message.offline"
                      class="badge badge-secondary live-agent place-offline">Pendente</span>
                    <strong v-if="!message.automatic && !message.me">
                      <template v-if="message.automatic || message.typeUser === 'AGENT'">
                        {{ message.username }}
                      </template>
                      <template v-else>
                        {{ $parent.currentName }}
                      </template>
                    </strong>
                    <!-- <div class="live-message-opts" v-if="!$parent.fromTranscript && !message.automatic">
                      <b-dropdown :right="message.me" variant="outline-secondary">
                        <b-dropdown-item>Responder</b-dropdown-item>
                        <b-dropdown-item @click="$live.deleteMessage(message)" v-if="message.me">Deletar</b-dropdown-item>
                      </b-dropdown>
                    </div> -->
                    <div class="live-message-content">
                      <template v-if="message.status === 'DELETED'">
                        <span> <i class="fas fa-ban"></i> Mensagem apagada </span>
                      </template>
                      <template v-else>
                        <div v-if="message.attachments && message.attachments.length > 0"
                          class="live-message-attachments">
                          <template v-for="file in message.attachments">
                            <button v-if="file.type === 'video'" :key="file.id" class="live-media-preview"
                              @click="$parent.showFile(file)">
                              <video :src="file.uri" :type="file.media_type"></video>
                            </button>
                            <button v-else-if="file.type === 'audio'" :key="file.id" class="live-media-preview"
                              @click="$parent.showFile(file)">
                              <audio :src="file.uri" :type="file.media_type" controls preload="metadata"></audio>
                            </button>
                            <button v-else-if="file.type === 'image'" :key="file.id" class="live-media-preview"
                              @click="$parent.showFile(file)">
                              <img :src="file.uri" />
                            </button>
                            <template v-else>
                              <a :key="file.id" :href="file.uri" class="live-external" target="_blank">
                                <i class="fas fa-file"></i>
                                <span class="live-attach-name">{{ file.name }}</span>
                                <!-- <span class="live-attach-name">{{file.uri | basename}}</span> -->
                                <div class="live-attach-type">
                                  {{ file.media_type || file.type }}
                                </div>
                              </a>
                            </template>
                          </template>
                        </div>
                        <div class="live-message-text" @click="handleInteractive"
                          v-html="interactive($live.markdown(message.message, true))">
                        </div>
                      </template>
                    </div>
                    <div class="live-message-details">
                      <time :title="message.sentAt" class="live-message-time">
                        {{ message.sentAt | relativeDate }}
                        <template v-if="message.status === 'FAIL'">
                          (não entregue) <i class="fas fa-exclamation-triangle icon-fail"></i>
                        </template>
                      </time>
                      <span v-if="message.status === 'DELIVERED'" class="live-msg-status">
                        <svg class="" fill="none" height="11" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 11"
                          width="16">
                          <path
                            d="M 11.071 0.653 C 10.991 0.585 10.889 0.551 10.767 0.551 C 10.619 0.551 10.492 0.611 10.386 0.729 L 4.197 8.365 L 1.791 6.093 C 1.749 6.046 1.698 6.01 1.639 5.985 C 1.58 5.959 1.518 5.947 1.455 5.947 C 1.324 5.947 1.209 5.995 1.112 6.093 L 0.801 6.404 C 0.708 6.493 0.661 6.605 0.661 6.74 C 0.661 6.876 0.708 6.99 0.801 7.083 L 3.797 10.079 C 3.945 10.215 4.112 10.282 4.298 10.282 C 4.404 10.282 4.506 10.259 4.603 10.212 C 4.7 10.166 4.781 10.1 4.844 10.016 L 11.49 1.599 C 11.562 1.501 11.598 1.402 11.598 1.3 C 11.598 1.144 11.535 1.019 11.408 0.926 L 11.071 0.653 Z"
                            fill="currentColor"></path>
                        </svg>
                      </span>
                      <span v-else-if="message.status === 'READ'" class="live-msg-status">
                        <svg class="" fill="none" height="11" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 11"
                          width="16">
                          <title>msg-dblcheck</title>
                          <path
                            d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                            fill="currentColor"></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div class="live-message-source">
                    <template v-if="currentChannel">
                      <i v-if="currentChannel === 'widget'" class="fas fa-globe"></i>
                      <i v-else-if="currentChannel === 'widget-java'" class="fas fa-globe"></i>
                      <i v-else-if="currentChannel === 'messenger'" class="fab fa-facebook-messenger"></i>
                      <i v-else-if="currentChannel === 'whatsapp'" class="fab fa-whatsapp"></i>
                      <i v-else-if="currentChannel === 'whatsapp-java'" class="fab fa-whatsapp"></i>
                      <i v-else-if="currentChannel === 'whatsapp-web'" class="fab fa-whatsapp"></i>
                      <i v-else-if="currentChannel === 'rcs'" class="fab fa-google"></i>
                      <i v-else-if="currentChannel === 'rcs-google'" class="fab fa-google"></i>
                      <i v-else-if="currentChannel === 'telegram'" class="fab fa-telegram"></i>
                      <i v-else-if="currentChannel === 'instagram'" class="fab fa-instagram"></i>
                      <i v-else-if="currentChannel === 'email'" class="fas fa-envelope"></i>
                      <i v-else-if="currentChannel === 'google-business'" class="fab fa-google"></i>
                      <!-- <template v-if="currentChannel === 'widget' || $parent.actualRoom.channel === 'widget-java'">
                        webchat
                      </template>
  <template v-else-if="currentChannel === 'whatsapp-java'">whatsapp</template>
  <template v-else>{{$parent.actualRoom.channel}}</template> -->
                    </template>
                    <template v-else><i class="fas fa-comment-dots"></i> webchat</template>
                  </div>
                </div>
                <!-- -->
              </div>
            </template>
          </div>
        </template>

        <!-- em andamento -->
        <div v-if="currentMessages.length" class="omni-transcript today">
          <!-- <pre>{{transcript.roomId}}</pre> -->
          <header class="omni-transcript-header">
            <div class="card">{{ $parent.actualRoom.createdAt | relativeDays }}</div>
          </header>
          <template v-for="message in currentMessages">
            <div :key="`message_${message.id}`" :class="{
              me: message.me,
              automatic: message.automatic,
              deleted: message.status === 'DELETED',
              fail: message.status === 'FAIL',
              'another-agent': message.typeUser === 'AGENT' && !message.me,
              'place-offline': message.offline,
            }" class="live-message">
              <!-- <div>{{message.id}}</div> -->
              <!-- <div class="live-message-group-date" v-if="index % 10 === 1" :title="message.sentAt">
                <h5>{{ message.sentAt | relativeDate }}</h5>
              </div> -->

              <div class="live-message-box">
                <div class="live-message-inner">
                  <picture v-if="
                    !message.automatic &&
                    message.typeUser !== 'AGENT' &&
                    !message.me &&
                    message.picture &&
                    $live.profilePics[$parent.currentRoom.roomId]
                  ">
                    <img :src="message.picture || $live.profilePics[$parent.currentRoom.roomId]" />
                  </picture>
                  <span v-if="message.automatic" class="badge bg-warning live-agent">{{
                    message.typeUser === 'BOT' ? 'BOT' : 'Automática'
                  }}</span>
                  <span v-else-if="message.typeUser === 'AGENT' && !message.me"
                    class="badge bg-primary live-agent">Agente</span>
                  <span v-else-if="message.offline"
                    class="badge badge-secondary live-agent place-offline">Pendente</span>
                  <strong v-if="!message.automatic && !message.me">
                    <template v-if="message.automatic || message.typeUser === 'AGENT'">
                      {{ message.username }}
                    </template>
                    <template v-else>
                      {{ $parent.currentName }}
                    </template>
                  </strong>
                  <!-- <div class="live-message-opts" v-if="!$parent.fromTranscript && !message.automatic">
                    <b-dropdown :right="message.me" variant="outline-secondary">
                      <b-dropdown-item>Responder</b-dropdown-item>
                      <b-dropdown-item @click="$live.deleteMessage(message)" v-if="message.me">Deletar</b-dropdown-item>
                    </b-dropdown>
                  </div> -->
                  <div class="live-message-content">
                    <template v-if="message.status === 'DELETED'">
                      <span> <i class="fas fa-ban"></i> Mensagem apagada </span>
                    </template>
                    <template v-else>
                      <div v-if="message.attachments && message.attachments.length > 0"
                        class="live-message-attachments">
                        <template v-for="file in message.attachments">
                          <button v-if="file.type === 'video'" :key="file.id" class="live-media-preview"
                            @click="$parent.showFile(file)">
                            <video :src="file.uri" :type="file.media_type"></video>
                          </button>
                          <button v-else-if="file.type === 'audio'" :key="file.id" class="live-media-preview"
                            @click="$parent.showFile(file)">
                            <audio :src="file.uri" :type="file.media_type" controls preload="metadata"></audio>
                          </button>
                          <button v-else-if="file.type === 'image'" :key="file.id" class="live-media-preview"
                            @click="$parent.showFile(file)">
                            <img :src="file.uri" />
                          </button>
                          <template v-else>
                            <a :key="file.id" :href="file.uri" class="live-external" target="_blank">
                              <i class="fas fa-file"></i>
                              <span class="live-attach-name">{{ file.name }}</span>
                              <!-- <span class="live-attach-name">{{file.uri | basename}}</span> -->
                              <div class="live-attach-type">{{ file.media_type || file.type }}</div>
                            </a>
                          </template>
                        </template>
                      </div>
                      <div v-if="currentChannel === 'email'" class="live-message-text" v-html="message.message"></div>

                      <div v-else class="live-message-text" @click="handleInteractive"
                        v-html="interactive($live.markdown(message.message, true))"></div>
                    </template>
                  </div>
                  <div class="live-message-details">
                    <time :title="message.sentAt" class="live-message-time">
                      {{ message.sentAt | relativeDate }}
                      <template v-if="message.status === 'FAIL'">
                        (não entregue) <i class="fas fa-exclamation-triangle icon-fail"></i>
                      </template>
                    </time>
                    <span v-if="message.status === 'DELIVERED'" class="live-msg-status">
                      <svg class="" fill="none" height="11" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 11"
                        width="16">
                        <path
                          d="M 11.071 0.653 C 10.991 0.585 10.889 0.551 10.767 0.551 C 10.619 0.551 10.492 0.611 10.386 0.729 L 4.197 8.365 L 1.791 6.093 C 1.749 6.046 1.698 6.01 1.639 5.985 C 1.58 5.959 1.518 5.947 1.455 5.947 C 1.324 5.947 1.209 5.995 1.112 6.093 L 0.801 6.404 C 0.708 6.493 0.661 6.605 0.661 6.74 C 0.661 6.876 0.708 6.99 0.801 7.083 L 3.797 10.079 C 3.945 10.215 4.112 10.282 4.298 10.282 C 4.404 10.282 4.506 10.259 4.603 10.212 C 4.7 10.166 4.781 10.1 4.844 10.016 L 11.49 1.599 C 11.562 1.501 11.598 1.402 11.598 1.3 C 11.598 1.144 11.535 1.019 11.408 0.926 L 11.071 0.653 Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                    <span v-else-if="message.status === 'READ'" class="live-msg-status">
                      <svg class="" fill="none" height="11" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 11"
                        width="16">
                        <title>msg-dblcheck</title>
                        <path
                          d="M11.0714 0.652832C10.991 0.585124 10.8894 0.55127 10.7667 0.55127C10.6186 0.55127 10.4916 0.610514 10.3858 0.729004L4.19688 8.36523L1.79112 6.09277C1.7488 6.04622 1.69802 6.01025 1.63877 5.98486C1.57953 5.95947 1.51817 5.94678 1.45469 5.94678C1.32351 5.94678 1.20925 5.99544 1.11192 6.09277L0.800883 6.40381C0.707784 6.49268 0.661235 6.60482 0.661235 6.74023C0.661235 6.87565 0.707784 6.98991 0.800883 7.08301L3.79698 10.0791C3.94509 10.2145 4.11224 10.2822 4.29844 10.2822C4.40424 10.2822 4.5058 10.259 4.60313 10.2124C4.70046 10.1659 4.78086 10.1003 4.84434 10.0156L11.4903 1.59863C11.5623 1.5013 11.5982 1.40186 11.5982 1.30029C11.5982 1.14372 11.5348 1.01888 11.4078 0.925781L11.0714 0.652832ZM8.6212 8.32715C8.43077 8.20866 8.2488 8.09017 8.0753 7.97168C7.99489 7.89128 7.8891 7.85107 7.75791 7.85107C7.6098 7.85107 7.4892 7.90397 7.3961 8.00977L7.10411 8.33984C7.01947 8.43717 6.97715 8.54508 6.97715 8.66357C6.97715 8.79476 7.0237 8.90902 7.1168 9.00635L8.1959 10.0791C8.33132 10.2145 8.49636 10.2822 8.69102 10.2822C8.79681 10.2822 8.89838 10.259 8.99571 10.2124C9.09304 10.1659 9.17556 10.1003 9.24327 10.0156L15.8639 1.62402C15.9358 1.53939 15.9718 1.43994 15.9718 1.32568C15.9718 1.1818 15.9125 1.05697 15.794 0.951172L15.4386 0.678223C15.3582 0.610514 15.2587 0.57666 15.1402 0.57666C14.9964 0.57666 14.8715 0.635905 14.7657 0.754395L8.6212 8.32715Z"
                          fill="currentColor"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                <div class="live-message-source">
                  <template v-if="$parent.actualRoom.channel">
                    <i v-if="currentChannel === 'widget'" class="fas fa-globe"></i>
                    <i v-if="currentChannel === 'widget-java'" class="fas fa-globe"></i>
                    <i v-else-if="currentChannel === 'messenger'" class="fab fa-facebook-messenger"></i>
                    <i v-else-if="currentChannel === 'whatsapp'" class="fab fa-whatsapp"></i>
                    <i v-else-if="currentChannel === 'whatsapp-java'" class="fab fa-whatsapp"></i>
                    <i v-else-if="currentChannel === 'whatsapp-web'" class="fab fa-whatsapp"></i>
                    <i v-else-if="currentChannel === 'rcs'" class="fab fa-google"></i>
                    <i v-else-if="currentChannel === 'rcs-google'" class="fab fa-google"></i>
                    <i v-else-if="currentChannel === 'telegram'" class="fab fa-telegram"></i>
                    <i v-else-if="currentChannel === 'instagram'" class="fab fa-instagram"></i>
                    <i v-else-if="currentChannel === 'email'" class="fas fa-envelope"></i>
                    <i v-else-if="currentChannel === 'google-business'" class="fab fa-google"></i>
                    <!-- <template v-if="currentChannel === 'widget' || $parent.actualRoom.channel === 'widget-java'">
                      webchat
                    </template>
  <template v-else-if="currentChannel === 'whatsapp-java'">whatsapp</template>
  <template v-else>{{$parent.actualRoom.channel}}</template> -->
                  </template>
                  <template v-else><i class="fas fa-comment-dots"></i> webchat</template>
                </div>
              </div>
              <!-- -->
            </div>
          </template>
          <div v-if="uploading" class="live-message upload me">
            <div class="live-message-box">
              <div class="live-message-inner">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
            </div>
          </div>
          <!-- fechamento -->
          <div v-if="
            $parent.fromTranscript &&
            !$parent.currentRoom.fromIdle &&
            !$parent.currentRoom.fromOffline
          " class="live-message">
            <div class="live-message-box">
              <div class="live-message-inner">
                <span class="badge bg-danger live-agent">{{
                  $tc('generic-str.status.lbl-finished', 2)
                }}</span>
                <div class="live-message-content">
                  <span>
                    {{ $t('live.closed-at') }}
                    <time :title="$parent.currentRoom.closedAt">{{
                      $parent.currentRoom.closedAt | formatDate24
                    }}</time>
                  </span>
                </div>
                <div class="live-message-details">
                  <time :title="$parent.currentRoom.closedAt" class="live-message-time">
                    {{ $parent.currentRoom.closedAt | relativeDate }}
                  </time>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <pre>{{$parent.actualRoom}}</pre> -->

      <!-- resume
      1) sessão open/closing (vale para status: finalizado): /room/open-now/
      2) sessão closed (vale para status: aberto ou finalizado): sms ou template whats
      3) Abandonados: /room/idle-open-now/
      -->
      <template v-if="$parent.actualRoom.type !== 'agent-manager' && hasResume">
        <template v-if="resumeOpeningOpts">
          <div class="live-manager-actions live-resume-actions">
            <button class="live-manager-enter" @click="reqResumeOpen()">
              <i class="fas fa-undo"></i>
              <template v-if="$parent.actualRoom.session === 'closed'"> Iniciar conversa</template>
              <template v-else> Retomar conversa</template>
            </button>
          </div>
        </template>
        <template v-else-if="
          $parent.actualRoom.session === 'closed' && !$parent.actualRoom.solved && readOnly
        ">
          <ChatInputComponent v-if="newWhatsApp" :recipient="$parent.actualRoom.contact.mobileNumber"
            :room="this.$parent.actualRoom" :template="selectedWhatsappTemplate"></ChatInputComponent>
          <div v-else-if="resume.sending" class="live-resume-contact">
            <b-card :class="{ 'd-none': resume.sending !== 'none' }" no-body>
              <b-tabs v-model="tabResumeIndex" card>
                <b-tab active title="SMS">
                  <sms-input v-model="resume.sms.form.body" :normalize="resume.sms.form.normalize" :variables="[]" />
                  <div v-if="$parent.currentRoom.fromOffline" class="py-2">
                    <input id="markassolved-modal-active" v-model="markAsSolvedAfter"
                      class="tgl tgl-light tgl-primary markassolved-modal-change" type="checkbox" />
                    <label class="tgl-btn" for="markassolved-modal-active"></label> Marcar como
                    resolvido
                  </div>
                  <div class="py-2">
                    <button :disabled="!smsFields" class="btn btn-primary btn-icon --btn-outline-primary" type="button"
                      @click="sendResumeSms()">
                      Enviar SMS
                    </button>
                  </div>
                </b-tab>
                <b-tab v-if="oldWhatsApp" title="Whatsapp">
                  <WhatsApp360 :room="$parent.currentRoom" />
                </b-tab>
                <b-tab v-if="newWhatsApp">
                  <template #title>
                    WhatsApp <span class="badge badge-warning">Beta</span>
                  </template>
                  <div class="sr-only">roomNumber: {{ roomNumber }}</div>
                  <div class="whatsapp-meta-component">
                    <whatsapp-send-form :clientRoomId="$parent.actualRoom.clientId" :extras="false"
                      :recipients="roomNumber" @success="resume.sending = 'done'" />
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
            <div v-if="resume.sending === 'sending'" class="p-4 text-center">
              <b-spinner small type="grow"></b-spinner>
              <b-spinner small type="grow"></b-spinner>
              <b-spinner small type="grow"></b-spinner>
            </div>
            <div v-else-if="resume.sending === 'done'">
              <div class="card">
                <div class="card-body p-4 text-center">
                  Solicitação de contato enviado com sucesso!
                </div>
              </div>
            </div>
          </div>
          <div v-else class="live-manager-actions live-resume-actions">
            <!-- <button class="live-manager-enter bg-danger" @click="markAsSolved()" v-if="actualRoom.fromOffline">
              <i class="fas fa-check"></i> Marcar como resolvido
            </button> -->
            <button class="live-manager-enter" @click="
              resume.sending = 'none';
            reqTriggerScroll();
            ">
              <i class="fas fa-undo"></i> Iniciar conversa
            </button>
          </div>
        </template>
      </template>
      <!-- resume -->
    </template>
    <template v-if="$parent.oldRoom === null && !readOnly && !$live.loadingMessages && $live.profiles">
      <div v-if="!managerEnter && $parent.isManager">
        <div class="live-manager-actions">
          <button class="live-manager-enter" @click="managerEnter = true">
            <!-- <i class="fas fa-key"></i> -->
            <span class="material-icons-outlined">vpn_key</span>
            Entrar na conversa
          </button>
        </div>
      </div>
      <ChatInputComponent v-else-if="!allowSend" :recipient="$parent.actualRoom.contact.mobileNumber"
        :room="this.$parent.actualRoom" :sender-number="$parent.sender" :template="selectedWhatsappTemplate">
      </ChatInputComponent>
      <div v-else :data="`${92 + (message.split('\n').length - 1) * 16}px`"
        :style="{ xHeight: `${92 + (message.split('\n').length - 1) * 16}px` }" class="live-input">
        <div v-if="roomId" class="live-input-out">
          <div class="live-input-in">
            <aside :class="{ show: autocompleteSampleVisible }" class="window-listing-sample-msgs card">
              <div v-if="$live.sampleMessagesLoading" class="p-2">
                <div class="qt-loader qt-loader-mini text-center">
                  <b-spinner small type="grow" variant="primary"></b-spinner>
                  <b-spinner small type="grow" variant="primary"></b-spinner>
                  <b-spinner small type="grow" variant="primary"></b-spinner>
                </div>
              </div>
              <nav v-else-if="autocompleteSampleMsgs.length" class="list-group">
                <template v-for="(sample, i) in autocompleteSampleMsgs">
                  <button :key="i" class="list-group-item list-group-item-action" @click="chooseSampleMessage(sample)">
                    <div class="d-flex">
                      <strong class="flex-fill">{{ sample.key }}</strong>
                      <div class="flex-fill">
                        <div class="sample-item">{{ sample.message }}</div>
                      </div>
                    </div>
                  </button>
                </template>
              </nav>
              <div v-else class="p-2 notfound">Não há mensagens prontas</div>
            </aside>
            <template v-if="currentChannel === 'email'">
              <ckeditor ref="inputMessage" v-model="messages[$parent.currentRoom.roomId]" :config="configEditor"
                :readonly="createSampleMsg" @input="refreshMessage += 1" @keyup="inputRequestSamples" />
            </template>

            <template v-else>
              <textarea ref="inputMessage" v-model="messages[$parent.currentRoom.roomId]" :readonly="createSampleMsg"
                :xStyle="{ height: `${message.split('\n').length + 1}em` }" class="live-input-textarea"
                placeholder="Digite aqui a sua mensagem" type="text" @blur="beforeSentTyping(false)"
                @focus="beforeSentTyping(true)" @input="refreshMessage += 1" @keyup="inputRequestSamples"
                @paste="previewFileFromClipboard" @keydown.enter.prevent="sendMessage"></textarea>
            </template>
          </div>
          <div v-if="messageDetails.length > 0" class="live-input-count">
            <span :class="{
              exceed: messageDetails.length > messageDetails.limit,
            }">{{ messageDetails.length }}</span>/{{ messageDetails.limit }}
          </div>
        </div>

        <div class="tools d-flex justify-content-between">
          <div class="d-flex">
            <div v-if="beforeUpload !== null" class="live-file-preview card">
              <header class="card-header d-flex justify-content-between">
                <div class="py-2">
                  <strong>{{ beforeUpload.source.name }}</strong>
                </div>
                <div class="py-2">
                  <button @click="resetPreviewFile()">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </header>
              <div class="card-body">
                <aside>
                  <img v-if="beforeUpload.source.type.indexOf('image/') === 0" :src="beforeUpload.url" controls />
                  <audio v-else-if="beforeUpload.source.type.indexOf('audio/') === 0" :src="beforeUpload.url" controls
                    preload="metadata"></audio>
                  <video v-else-if="beforeUpload.source.type.indexOf('video/') === 0" :src="beforeUpload.url"
                    controls></video>
                  <object v-else-if="beforeUpload.source.type.indexOf('pdf') !== -1" :data="beforeUpload.url"
                    type="application/pdf">
                    <p>Seu navegador não suporta PDF</p>
                  </object>
                  <h3 v-else>Pré-visualização indisponível</h3>
                  <div class="d-flex justify-content-between live-file-preview-meta">
                    <div>{{ beforeUpload.source.size | fileSize }}</div>
                    <div>{{ beforeUpload.source.type }}</div>
                  </div>
                </aside>
              </div>
            </div>
            <div v-if="recordResolving || recorder !== null || audioURL !== null"
              class="live-file-preview live-record-audio card">
              <header class="card-header d-flex justify-content-between">
                <div class="py-2">
                  <strong>Gravando áudio</strong>
                </div>
                <div class="py-2">
                  <button @click="resetRecordAudio()">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </header>
              <div class="card-body">
                <aside>
                  <div v-if="recordResolving" class="recording-write">
                    <p>Comprimindo a gravação</p>
                    <b-spinner small type="grow"></b-spinner>
                    <b-spinner small type="grow"></b-spinner>
                    <b-spinner small type="grow"></b-spinner>
                  </div>
                  <div v-else-if="audioURL !== null" class="record">
                    <audio ref="audioURLRef" :src="audioURL" controls preload></audio>
                    <div class="row">
                      <div class="col-5">
                        <button class="btn btn-outline-secondary audio-cancel" @click="resetRecordAudio()">
                          Cancelar
                        </button>
                      </div>
                      <div class="col-2"></div>
                      <div class="col-5">
                        <button class="btn btn-primary audio-send" @click="sendRecordAudio()">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="record">
                    <div class="timer">{{ recordTimer | roundTime }}</div>
                    <button class="audio-finish" @click="finishRecordAudio()">
                      <i class="fas fa-microphone"></i>
                    </button>
                    <footer><strong>Dica:</strong> clique no ícone para concluir</footer>
                  </div>
                </aside>
              </div>
            </div>
            <div v-if="isTemplate" class="alert alert-warning black-color">
              Passou de 24h, você precisa enviar um template
              <button class="btn btn-primary btn-sm to-right" @click="sendTemplate">
                {{ $t('generic-str.send') }}
              </button>
              <div style="clear: both"></div>
            </div>
            <div>
              <template v-if="currentChannel !== 'email'">
                <button class="live-input-btn" @click="requestFile" :disabled="filePreviewShowed">
                  <div class="up-spinner" v-if="uploading">
                    <span class="material-icons-outlined">
                      autorenew
                    </span>
                  </div>
                  <span v-else class="material-icons-outlined"> attach_file </span>
                  <span class="sr-only">
                    {{ uploading ? 'Enviando...' : 'Enviar arquivo' }}
                  </span>
                </button>
              </template>
            </div>
            <Emojis bottom="52px" icon="emoji_emotions" left="10px" setClass="none" @select="addEmoji" />
            <div>
              <aside v-if="createSampleMsg" class="window-create-sample-msg card">
                <div class="relative">
                  <button class="window-create-sample-close" @click="toggleCreateSampleMessage()">
                    <i class="fas fa-times"></i>
                  </button>
                  <div v-if="
                    $live.departmentsLoading ||
                    $live.sampleMessagesLoading ||
                    createSampleMsgNew == 2
                  " class="qt-block-ui"></div>
                  <div v-if="createSampleMsgNew === 0" class="row">
                    <div class="col-6">
                      <h1>Mensagens Prontas</h1>
                      <p>Crie uma mensagem pronta</p>
                      <div>
                        <img alt="sample message" height="142" src="@/assets/sample-messages.svg" />
                      </div>
                      <button class="btn btn-primary" @click="createSampleMsgNew = 1">
                        Nova mensagem
                      </button>
                    </div>
                    <div class="col-6">
                      <div class="input-group filter-msgs mb-3">
                        <div class="input-group-prepend">
                          <span id="filter-sample-messages" class="input-group-text">
                            <i class="fas fa-search"></i>
                          </span>
                        </div>
                        <input v-model="filterSamples" aria-describedby="filter-sample-messages"
                          aria-label="Filtrar mensagens" class="form-control" placeholder="Filtrar" type="text" />
                      </div>
                      <div class="sample-messages">
                        <div v-if="!filtredSamples.length" class="p-2">Sem mensagens</div>
                        <nav v-else class="list-group">
                          <template v-for="(sample, i) in filtredSamples">
                            <button :key="i" class="list-group-item list-group-item-action"
                              @click="chooseSampleMessage(sample)">
                              <strong>{{ sample.key }}</strong>
                              <div class="sample-dones">{{ sample.message }}</div>
                            </button>
                          </template>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <template v-else-if="createSampleMsgNew !== 0">
                    <h1>Mensagens Prontas</h1>
                    <div class="row new-sample">
                      <div class="col-6">
                        <div class="form-group">
                          <label class="form-label" for="newSampleKey">Titulo</label>
                          <input id="newSampleKey" v-model="sampleForm.key" class="form-control" placeholder="Titulo..."
                            type="text" />
                        </div>
                        <div class="form-group">
                          <label class="form-label" for="newSampleMessage">Conteudo</label>
                          <textarea id="newSampleMessage" v-model="sampleForm.message" class="form-control"></textarea>
                        </div>
                        <div class="row form-footer">
                          <div class="col-6">
                            <button class="btn btn-block btn-primary" @click="createSampleAction">
                              Salvar
                            </button>
                          </div>
                          <div class="col-6">
                            <button class="btn btn-block btn-outline-secondary" @click="createSampleMsgNew = 0">
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <strong class="mb-2 d-block">Disponível para</strong>
                        <div class="form-check">
                          <input id="all" v-model="sampleForm.availableTo" class="form-check-input" name="exampleRadios"
                            type="radio" value="all" />
                          <label class="form-check-label" for="all">Todos da equipe</label>
                        </div>
                        <div class="form-check">
                          <input id="me" v-model="sampleForm.availableTo" class="form-check-input" name="exampleRadios"
                            type="radio" value="me" />
                          <label class="form-check-label" for="me">Apenas para mim</label>
                        </div>
                        <hr />
                        <div v-if="!$live.departments.length" class="p-2">
                          Não há departamentos cadastrados
                        </div>
                        <template v-for="(department, i) in $live.departments">
                          <div :key="i" class="form-check">
                            <input :id="`department_${department.id}`" v-model="sampleForm.availableTo"
                              :value="department.id" class="form-check-input" type="radio" />
                            <label :for="`department_${department.id}`" class="form-check-label">
                              {{ department.name }}
                            </label>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                  <!-- <template v-else-if="createSampleMsgNew === 2">
                    I am popover <b>component</b> content! 2
                  </template> -->
                </div>
              </aside>
              <button :disabled="filePreviewShowed" class="btn-create-sample-msg" @click="toggleCreateSampleMessage()">
                <!-- <i class="fas fa-archive fa-fw"></i> -->
                <!-- <i class="fas fa-sparkles fa-fw"></i>
                <i class="fas fa-comments-alt fa-fw"></i>
                <i class="fas fa-comment-alt-check fa-fw"></i> -->
                <!-- <img src="@/assets/fast-messages.svg" alt="Mensagens prontas" /> -->
                <span class="material-icons-outlined">quickreply</span>
              </button>
            </div>
            <!--
              <button>
                <i class="fas fa-paperclip icon-live c-pointer" style="margin-left: 10px"></i>
              </button>
            -->
            <!--
              <b-modal id="live-modal-templates" ref="ref-templates" title="Selecionar template">
                <template>
                  <p>teste</p>
                </template>
                <template #modal-footer="{ ok, cancel }">
                  <b-button size="md" variant="danger" @click="cancel()">
                    Cancelar
                  </b-button>
                  <b-button size="md" variant="success" @click="ok()">
                    Selecionar
                  </b-button>
                </template>
              </b-modal>
            -->
            <!--
              <b-dropdown id="live-attach" dropup v-if="beforeUpload === null">
                <template #button-content>
                  <span class="material-icons-outlined">more_vert</span>
                </template>
                <! --
                <b-dropdown-item href="#" @click="showTemplates()">
                  <i class="far fa-file-alt"></i> Selecionar um template
                </b-dropdown-item>
                -- >
                <b-dropdown-item href="#" @click="requestFile">
                  <i class="fas" :class="{
                    'up-spinner': uploading,
                    'fa-paperclip': !uploading,
                  }"></i>
                  {{uploading ? 'Enviando...' : 'Enviar arquivo'}}
                </b-dropdown-item>
              </b-dropdown>
            -->
            <input id="live-attach-input" ref="attachInput" type="file" @change="previewFileFromInput()" />
          </div>
          <div class="tools-by-channel">
            <template v-if="beforeUpload === null && !message">
              <template v-if="currentChannel !== 'email'">
                <button class="live-speak live-input-btn" :disabled="uploading" @click="startRecordAudio()">
                  <span class="material-icons-outlined">mic</span>
                  <span class="sr-only">Gravar áudio</span>
                </button>
              </template>
              <!-- template v-if="currentChannel === 'rcs-google' || currentChannel === 'rcs-java'">
                <button
                  class="live-input-btn"
                  :disabled="uploading"
                  @click="() => {}">
                  <span class="material-icons-outlined">view_carousel</span>
                  <span class="sr-only">Enviar carrossel</span>
                </button>
                <button
                  class="live-input-btn"
                  :disabled="uploading"
                  @click="() => {}">
                  <span class="material-icons-outlined">pages</span>
                  <span class="sr-only">Enviar cartão</span>
                </button>
              </template -->
            </template>
            <template v-else-if="!isTemplate">
              <button :disabled="beforeUpload === null &&
                (!message ||
                  !messageDetails.length ||
                  messageDetails.length > messageDetails.limit)
                " class="live-enter live-input-btn" @click="sendMessage()">
                <span class="material-icons-outlined">send</span>
                <span class="sr-only">Enviar mensagem</span>
              </button>
            </template>
          </div>
          <!-- <i class="fas fa-paperclip icon-live c-pointer" style="margin-left: 10px" @click="sendFile"></i> -->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Emojis from '@/components/Emojis.vue';
import MicRecorder from 'mic-recorder-to-mp3';

import SmsInput from '@/components/form/SmsInput.vue';
// import SelectInput from '@/components/SelectInput.vue';

import WhatsAppService from '@/services/whatsapp.service';
import axios from '@/plugins/axios';

import Swal from 'sweetalert2';
import moment from 'moment';

// old
import WhatsApp360 from '@/views/rebranding/live/WhatsApp360.vue';

// new
import WhatsappSendForm from '@/components/whatsappj/WhatsappSendForm.vue';
import ChatInputComponent from '@/components/chat/ChatInputComponent.vue';

// @ is an alias to /src
// import InfiniteLoading from 'vue-infinite-loading';
// import LiveService from '@/services/live.service';

// console.log('teste', [
//   LiveService.messages,
//   LiveService.history,
//   LiveService.hasNotifications,
//   LiveService.online,
//   LiveService.launch(),
// ]);

const INTERACTIVE_CONTENT = `<div>
  <button data-interactive="copy">
    <i class="far fa-copy"></i>
  </button>
</div>`;

export default {
  name: 'Conversation',
  components: {
    ChatInputComponent,
    // InfiniteLoading,
    Emojis,
    SmsInput,
    // SelectInput,
    WhatsApp360,
    WhatsappSendForm,
  },
  props: ['roomId', 'readOnly', 'openedWhatsappTemplate'],
  data() {
    return {
      configEditor: {
        height: 100,
        fullPage: false,
        allowedContent: true,
        applicationTitle: false,
        toolbarGroups: [
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
          { name: 'forms', groups: ['forms'] },
          { name: 'colors', groups: ['colors'] },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
          { name: 'links', groups: ['links'] },
          { name: 'insert', groups: ['insert'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'tools', groups: ['tools'] },
          { name: 'others', groups: ['others'] },
          { name: 'about', groups: ['about'] },
        ],
        removeButtons:
          'Source,Save,NewPage,ExportPdf,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Form,Checkbox,Radio,Textarea,TextField,Select,Button,HiddenField,ImageButton,NumberedList,Outdent,Blockquote,BidiLtr,BidiRtl,Language,Table,Smiley,SpecialChar,PageBreak,Iframe,Styles,Maximize,About,CreateDiv,Indent,BulletedList,Format,Font,FontSize,ShowBlocks,BGColor',
        removePlugins: 'elementspath',
        resize_enabled: false,
        versionCheck: false,
      },

      animateContactsItems: [],
      animateContactsTimeout: 0,
      animateContactsCurrent: 0,

      requestSamplesTimeout: 0,

      createSampleMsg: false,
      createSampleMsgNew: 0, // 0 intro, 1 new, 2 saving

      sampleForm: {
        key: '',
        message: '',
        availableTo: 'me',
      },
      selectedWhatsappTemplate: this.openedWhatsappTemplate,

      filterSamples: '',
      // samples: [
      //   { key: 'aaa', message: 'gato gato gato gato gato gato gato gato gato gato gato gato gato gato gato gato' },
      //   { key: 'bbb', message: 'rato rato rato rato rato rato rato rato rato rato rato rato rato rato' },
      //   { key: 'ccc', message: 'foo bar baz foo bar baz foo bar baz foo bar baz foo bar baz' },
      // ],

      markAsSolvedAfter: false,
      audioURL: null,
      audioData: null,
      recorder: null,
      recordTimer: 0,
      recordMax: 120,
      recordInterval: 0,
      recordResolving: false,
      markdownDom: null,
      // message: '',
      refreshMessage: 0,
      messages: {},
      limits: {
        mail: 4096,
        email: 4096,
        whatsapp: 4096,
        'whatsapp-java': 4096,
        'whatsapp-web': 4096,

        widget: 4096,
        'widget-java': 4096,

        messenger: 4096,
        telegram: 4096,
        instagram: 2000,
        rcs: 255,
        'rcs-google': 255,
        smsGSM: 160,
        smsUnicode: 70,
        'google-business': 996,
      },
      isTemplate: false,
      templateName: null,
      timeoutTyping: 0,
      // messageDetails.limit: 0,
      // messageDetails.length: 0,
      uploading: false,
      beforeUpload: null,
      managerEnter: false,
      scrollTimeout: 0,

      whatsAppTemplates: [],
      whatsAppTemplate: null,
      whatsAppActive: false,

      // new whatsapp
      newWhatsAppId: null,

      newWhatsAppNumbersFetched: false,
      newWhatsAppNumbers: [],

      newWhatsAppTemplatesFetched: false,
      newWhatsAppTemplates: [],

      newWhatsAppTemplateIdSelected: null,
      newWhatsAppTemplateSelected: null,

      tabResumeIndex: 0,

      resume: {
        sending: null,
        sms: {
          form: {
            clientId: '',
            body: '',
            normalize: false,
          },
        },
        whatsapp: {
          form: {
            clientId: '',
            type: 'simple',
            from: 'Default',
            to: '',
            body: '',
            file: '',
            normalize: false,
            flash: false,
            template: {},
            params: null,
          },
        },
      },
    };
  },
  filters: {
    relativeDays(date) {
      const secs = (new Date() - new Date(date).getTime()) / 1000;

      if (secs < 86400) return 'Hoje';

      if (secs < 172800) return 'Ontem';

      return moment(date).format('DD/MM/YYYY');
    },
    onlyDate(data) {
      return `${new Date(data).toLocaleDateString()}`;
    },
    fileSize: (value) => {
      if (value > 1073741824) return `${Math.floor(value / 1073741824)} GB`;

      if (value > 1048576) return `${Math.floor(value / 1048576)} MB`;

      if (value > 1024) return `${Math.floor(value / 1024)} kB`;

      return `${Math.floor(value * 1024)} bytes`;
    },
    roundTime: (value) => {
      const hours = Math.floor(value / 60 / 60);
      const mins = Math.floor((value / 60) % 60);
      const secs = Math.floor(value % 60);
      return `${hours}:${mins}:${secs}`.replace(/\b(\d)\b/g, '0$1').replace(/^00:/, '');
    },
  },
  computed: {
    allowSend() {
      console.log('Channel: ', this.currentChannel);
      if (this.currentChannel === 'whatsapp' || this.currentChannel === 'whatsapp-java') {
        const clientMessages = this.currentMessages.filter(
          (message) => message.typeUser === 'CLIENT',
        );

        if (clientMessages.length) {
          const lastMessage = clientMessages[clientMessages.length - 1];
          const sessionDuration = new Date() - lastMessage.sentAt;

          return sessionDuration / 1000 / 3600 < 24;
        }
        return false;
      }
      return true;
    },
    currentChannel() {
      return this.$parent?.actualRoom?.channel;
    },
    currentRoomId() {
      return this.$parent?.actualRoom?.roomId;
    },
    filePreviewShowed() {
      return (
        this.beforeUpload !== null ||
        this.recordResolving ||
        this.recorder !== null ||
        this.audioURL !== null
      );
    },
    message() {
      this.refreshMessage;
      const roomId = this.$parent?.currentRoom?.roomId;
      console.info('chooseSampleMessage:message', [roomId, this.refreshMessage]);
      return this.messages[roomId] || '';
    },
    messageDetails() {
      const data = { limit: 0, length: 0 };

      if (!this.$parent.currentRoom) return data;

      const channel = this.currentChannel;
      const message = this.message;

      if (channel === 'sms') {
        if (/^[ -~\n]*$/.test(message)) {
          // GSM
          data.limit = this.limits.smsGSM;
          data.length = message.length;
        } else {
          // UNICODE
          data.limit = this.limits.smsUnicode;
          // data.length = message.replace(/%([0-9A-F]{2})/gi, (match, hex) => String.fromCharCode(parseInt(hex, 16))).length;
        }
      } else {
        data.limit = this.limits[channel];
        data.length = message.length;
      }

      return data;
    },
    filtredSamples() {
      const filter = this.filterSamples;
      const samples = this.$live.sampleMessages;

      if (!filter) return samples;

      return samples.filter(
        (sample) => sample.key.indexOf(filter) !== -1 || sample.message.indexOf(filter) !== -1,
      );
    },
    autocompleteSampleMsgs() {
      let filter = this.message.trim();
      const samples = this.$live.sampleMessages;

      // console.log('autocompleteSampleMsgs1', { filter });

      if (filter.indexOf('/') !== 0 || samples.length === 0) return [];

      // if (samples.length === 0) this.$live.requestSampleMessages();

      if (filter === '/') return samples;

      filter = filter.replace(/^\/(\w+).*/, '$1');

      // console.log('autocompleteSampleMsgs2', { filter });

      return samples.filter((sample) => sample.key.indexOf(filter) === 0);
    },
    autocompleteSampleVisible() {
      return (
        !this.createSampleMsg &&
        (this.autocompleteSampleMsgs.length > 0 ||
          (this.$live.sampleMessagesLoading && this.message.trim().indexOf('/') === 0))
      );
      // return this.autocompleteSampleMsgs.length;
    },
    resumeOpeningOpts() {
      const room = this.$parent.actualRoom;
      // return room && room.session !== 'closed' && room.status !== 'OPEN';
      return (
        room.fromIdle || (room.session !== 'closed' && (room.fromOffline || room.fromTranscripts))
      );
    },
    hasResume() {
      return (
        (this.$parent.actualRoom.fromIdle || !this.allowSend || this.resumeOpeningOpts) &&
        this.$store?.state?.account?.['is_agent']
      );
    },
    account() {
      return this.$store.state.account;
    },
    smsFields() {
      const body = this.resume.sms?.form?.body;
      return body && body.length > 3;
    },
    whatsAppFields() {
      if (!this.whatsAppTemplate || !this.whatsAppTemplates.length) return false;

      const params = this.resume.whatsapp?.form?.params;

      if (!params) return true;

      return params.length && params.findIndex((param) => param.text === '') === -1;
    },
    whatsAppBody() {
      const form = this.resume.whatsapp?.form;

      if (!form?.body) return '';

      const params = form.params;

      return form.body.replace(/\{\{(.*?)\}\}/g, (original, index) => {
        const entry = params.find((param) => param.index === parseInt(index, 10));
        return entry?.text || '';
      });
    },
    newWhatsApp() {
      // const account = this.$store.state.account;

      // const businessAccountsTemporary = [
      //   // '5cc32c49-7d10-4c2c-8aac-9dce25520d73',
      //   '142a07bd-25d6-4681-989d-d0cb0b4728ce',
      //   'cb83acd8-9746-4aeb-bda5-5d6bdffae4c3',
      //   '1c7c571c-5dfc-466c-925b-1c9cf3f93b41',
      //   'b0f68367-11b4-4796-8b37-5de7388fc6d7',
      //   '87449f80-72bc-4a81-96b3-aa2e3ed6f6df',
      // ];

      // return account.whitelabel_id === null || businessAccountsTemporary.indexOf(account.id) !== -1;

      return this.$live.isMeta;
    },
    oldWhatsApp() {
      return this.$live.is360;
    },
    roomNumber() {
      return this.$parent?.actualRoom?.contact?.mobileNumber || null;
    },
    currentMessages() {
      let messages;

      if (this.$parent.fromTranscript) {
        messages = this.$live.associativeTranscriptsMessages;
      } else {
        messages = this.$live.messages;
      }

      const currentRoomId = this.$parent.currentRoom?.roomId;

      if (messages?.length && currentRoomId) {
        messages = messages.filter((message) => message.roomId === currentRoomId);

        messages.sort((a, b) => {
          const result = new Date(a.sentAt) - new Date(b.sentAt);

          if (result < 0) return -1;

          if (result > 0) return 1;

          return 0;
        });

        return messages;
      }

      return [];
    },
    associativeTranscriptsHistory() {
      return [...this.$live.associativeTranscriptsHistory].sort((a, b) => {
        const result = new Date(a.createdAt) - new Date(b.createdAt);

        if (result < 0) return -1;

        if (result > 0) return 1;

        return 0;
      });
    },
  },
  mounted() {
    // this.$live.totalMessages[this.$parent.currentRoom.roomId]
    this.$root.$on('template.send', this.sendMessageTemplate);
    this.startAnimateContacts();
  },
  beforeDestroy() {
    // console.error('beforeDestroy');
    this.resetRecordAudio();
  },
  watch: {
    // currentMessages: {
    //   handler(newVal, oldVal) {
    //     console.log('currentMessages:: ', newVal);
    //   },
    //   deep: true,
    // },
    openedWhatsappTemplate(val) {
      this.selectedWhatsappTemplate = val;
    },
    whatsAppTemplate(newVal) {
      this.insertTemplate(newVal);
    },
    roomId() {
      this.managerEnter = false;
      this.markAsSolvedAfter = false;

      // console.log('roomId', this.roomId, this.readOnly);
      this.resetRecordAudio();

      setTimeout(() => {
        // console.log('this.readOnly', this.readOnly);
        if (!this.readOnly) this.reqTriggerScroll();

        this.startAnimateContacts();
      }, 100);

      this.resume.sending = null;
    },
    readOnly() { },
    // totalMessages() {
    //   console.log('totalMessages', this.totalMessages);
    // },
    messages: {
      handler(newValue, oldValue) {
        this.refreshMessage += 1;

        if ('draftUpdates' in this.$parent) this.$parent.draftUpdates += 1;
      },
      deep: true,
    },
  },
  methods: {
    inputRequestSamples(event) {
      const message = this.message.trim();

      clearTimeout(this.requestSamplesTimeout);

      if (message.length < 3 || message.indexOf('/') !== 0) return;

      this.requestSamplesTimeout = setTimeout(() => {
        this.$live.requestSampleMessages();
      }, 500);
    },
    hasTranscriptMessages(roomId) {
      return (
        this.$live.associativeTranscriptsMessages.filter((message) => roomId === message.roomId)
          .length > 0
      );
    },
    startAnimateContacts() {
      if (this.animateContactsTimeout) clearTimeout(this.animateContactsTimeout);

      const items = [];

      const item1 = this.$refs?.contactEmail;

      if (item1) items.push(item1);

      const item2 = this.$refs?.contactMobileNumber;

      if (item2) items.push(item2);

      const item3 = this.$refs?.contactPhone;

      if (item3) items.push(item3);

      if (!items.length) {
        setTimeout(() => this.startAnimateContacts(), 1000);
        return;
      }

      this.animateContactsItems = items;

      items.forEach((el, index) => {
        el.classList.toggle('current', index === 0);
      });

      this.animateContacts();
    },
    animateContacts() {
      let current = this.animateContactsCurrent + 1;

      if (current > this.animateContactsItems.length - 1) current = 0;

      this.animateContactsCurrent = current;

      this.animateContactsItems.forEach((el, index) => {
        el.classList.toggle('current', current === index);
      });

      this.animateContactsTimeout = setTimeout(() => {
        this.animateContacts();
      }, 2000);
    },
    bootWhatsAppTemplates() {
      this.whatsAppTemplates = [];

      // WhatsAppService.getClient().then((response) => {
      //   const active = response.data.active || false;

      //   this.whatsAppActive = active;

      //   if (active) this.fetchWhatsAppTemplates();
      // }).catch((ee) => {
      //   this.$toast.show({
      //     title: 'Conta WhatsApp',
      //     content: String(ee),
      //     type: 'danger',
      //   });
      // });

      this.whatsAppActive = true;

      this.fetchWhatsAppTemplates();
      // this.simulateWhatsAppTemplates();
    },
    bootWhatsAppTemplatesx() {
      this.whatsAppTemplates = [];

      // 360
      // WhatsAppService.getClient().then((response) => {
      //   const active = response.data.active || false;

      //   this.whatsAppActive = active;

      //   if (active) this.fetchWhatsAppTemplates();
      // }).catch((ee) => {
      //   this.$toast.show({
      //     title: 'Conta WhatsApp',
      //     content: String(ee),
      //     type: 'danger',
      //   });
      // });

      // Novo
      // if (this.newWhatsApp) {
      //   this.getWhatsNumbers();
      // } else {
      //   this.whatsAppActive = true;
      //   this.fetchWhatsAppTemplates();
      // }

      // Simulado
      // this.simulateWhatsAppTemplates();
    },
    simulateWhatsAppTemplates() {
      const response = {
        status: 'success',
        total: 6,
        data: {
          count: 6,
          filters: {},
          limit: 10,
          offset: 0,
          sort: ['id'],
          total: 6,
          waba_templates: [
            {
              category: 'MARKETING',
              components: [
                {
                  text: 'Olá.\n\nEscolha a opção desejada: ',
                  type: 'BODY',
                },
                {
                  text: 'Tst- Vinicius',
                  type: 'FOOTER',
                },
                {
                  buttons: [
                    {
                      text: 'Suporte',
                      type: 'QUICK_REPLY',
                    },
                    {
                      text: 'Financeiro',
                      type: 'QUICK_REPLY',
                    },
                    {
                      text: 'Comercial',
                      type: 'QUICK_REPLY',
                    },
                  ],
                  type: 'BUTTONS',
                },
              ],
              language: 'pt_BR',
              name: 'tstvinicius_botao03',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'ACCOUNT_UPDATE',
              components: [
                {
                  example: {
                    header_handle: [
                      'https://scontent.whatsapp.net/v/t61.29466-34/145750688_470574831486430_3739523328288718763_n.png?ccb=1-7&_nc_sid=57045b&_nc_ohc=CFQ_j9qjGJMAX9bSMm9&_nc_ht=scontent.whatsapp.net&edm=AH51TzQEAAAA&oh=01_AVzHH7sPME_ikvD4qrPqwJE5oRjSh-gq-WeQyhO5LfnXsw&oe=629C6A1D',
                    ],
                  },
                  format: 'IMAGE',
                  type: 'HEADER',
                },
                {
                  text: 'Olá tudo bem? 🤩\n\nVocê entrou em contato conosco fora de nosso horário de atendimento, somos da *Yup Chat*, em que podemos ajudar? 😇',
                  type: 'BODY',
                },
                {
                  text: 'Yup Chat | Relacionamento Inteligente',
                  type: 'FOOTER',
                },
                {
                  buttons: [
                    {
                      text: 'Acesse nosso site',
                      type: 'URL',
                      url: 'https://yup.chat/',
                    },
                    {
                      phone_number: '+558006911000',
                      text: 'Ligue para nós',
                      type: 'PHONE_NUMBER',
                    },
                  ],
                  type: 'BUTTONS',
                },
              ],
              language: 'pt_BR',
              name: 'inicio_atendimento',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: 'NONE',
              status: 'approved',
            },
            {
              category: 'ACCOUNT_UPDATE',
              components: [
                {
                  example: {
                    body_text: [['a', 'b', 'c', 'd', 'e', 'f']],
                  },
                  text: 'Corpo {{2}}{{1}}{{3}}{{4}}{{5}}{{6}}',
                  type: 'BODY',
                },
                {
                  format: 'TEXT',
                  text: 'Cabeçalho',
                  type: 'HEADER',
                },
              ],
              language: 'pt_BR',
              name: 'ola',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'ACCOUNT_UPDATE',
              components: [
                {
                  buttons: [
                    {
                      text: 'Sim ',
                      type: 'QUICK_REPLY',
                    },
                    {
                      text: 'Não',
                      type: 'QUICK_REPLY',
                    },
                  ],
                  type: 'BUTTONS',
                },
                {
                  text: 'Tst',
                  type: 'FOOTER',
                },
                {
                  text: 'Essa mensagem é um conteúdo para testes de envio interno.\n\nCaso tenha recebido, selecione uma das opções abaixo:',
                  type: 'BODY',
                },
                {
                  format: 'TEXT',
                  text: 'Olá',
                  type: 'HEADER',
                },
              ],
              language: 'pt_BR',
              name: 'test_vinicius101',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'TRANSACTIONAL',
              components: [
                {
                  text: 'Olá, podemos conversar agora?',
                  type: 'BODY',
                },
                {
                  format: 'TEXT',
                  text: 'Yup Chat',
                  type: 'HEADER',
                },
              ],
              language: 'pt_BR',
              name: 'reconnect_yup',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: '',
              status: 'approved',
            },
            {
              category: 'AUTO_REPLY',
              components: [
                {
                  text: 'Olá, tudo bem?\n\nObrigado pela participação em nosso treinamento e a curiosidade para conhecer melhor o WhatsApp Business API.\n\nAvalie de 0 a 5 a eficiência de envio desta mensagem.',
                  type: 'BODY',
                },
                {
                  text: 'Yup Chat | Relacionamento Inteligente',
                  type: 'FOOTER',
                },
                {
                  buttons: [
                    {
                      text: 'Visite nosso site...',
                      type: 'URL',
                      url: 'https://yup.chat/',
                    },
                  ],
                  type: 'BUTTONS',
                },
              ],
              language: 'pt_BR',
              name: 'treinamento_v1',
              namespace: '93c67064_8fce_47a9_b786_d80bb15a5d5f',
              rejected_reason: 'NONE',
              status: 'approved',
            },
          ],
        },
      };

      const templates = response?.data?.['waba_templates'];

      if (templates) this.parseWhatsAppData(templates);
    },

    /* old */
    fetchWhatsAppTemplates() {
      axios()
        .post('whatsapp/templates/list')
        .then((response) => {
          const templates = response?.data?.data?.['waba_templates'];

          console.log('fetchWhatsAppTemplates', templates, response.data);

          if (templates) {
            this.parseWhatsAppData(templates);
          } else {
            this.$toast.show({
              title: 'Templates WhatsApp',
              content: 'Formato inesperado do servidor',
              type: 'danger',
            });
          }
        })
        .catch((ee) => {
          this.$toast.show({
            title: 'Templates WhatsApp',
            content: String(ee),
            type: 'danger',
          });
        });
    },
    parseWhatsAppData(templates) {
      this.whatsAppTemplates = templates.filter((template) => {
        if (template.status !== 'approved') return false;

        return template.components.findIndex(
          (component) => component.format && component.format !== 'TEXT',
        );
      });
    },

    /* new */
    getWhatsNumbers() {
      this.newWhatsAppNumbersFetched = false;

      axios()
        .get('whatsapp/new/numbers')
        .then(
          (response) => {
            this.newWhatsAppNumbers = [];
            response.data.forEach((data) => {
              this.newWhatsAppNumbers.push(data);
            });
            this.newWhatsAppId = this.newWhatsAppNumbers[0].whatsapp_identification;
            this.fetchWhatsAppTemplatesNew(1);
          },
          (error) => {
            console.error(error);
          },
        )
        .finally(() => {
          this.newWhatsAppNumbersFetched = true;
        });
    },
    fetchWhatsAppTemplatesNew(page) {
      this.newWhatsAppTemplatesFetched = false;

      WhatsAppService.getNewTemplates(this.newWhatsAppId, {
        params: { page },
      })
        .then(
          (response) => {
            console.log('Templates');
            this.newWhatsAppTemplates = [];

            if (response && response.data && response.data.content) {
              response.data.content.forEach((template) => {
                if (
                  !template.buttons &&
                  template.body.text.indexOf('{{') === -1 &&
                  template.category === 'TRANSACTIONAL' &&
                  template.status === 'APPROVED' &&
                  !template.footer &&
                  template.status === 'APPROVED'
                ) {
                  this.newWhatsAppTemplates.push(template);
                }
              });
            }
            this.fetchedItens = true;
          },
          (error) => {
            console.error(error);
          },
        )
        .finally(() => {
          this.newWhatsAppTemplatesFetched = true;
        });
    },
    setWhatsAppTemplate() {
      const id = this.newWhatsAppTemplateIdSelected;

      this.newWhatsAppTemplateSelected = this.newWhatsAppTemplates.find(
        (template) => template.id === id,
      );
    },
    markAsRead() {
      const room = this.$parent.currentRoom;

      const agentId = room.agent_id || room.agentId;
      const isManager = this.$parent.isManager && agentId === this.$parent.currentAgent?.id;
      const status = room.status;
      const type = room.type;

      let req = false;
      let isMe = false;

      if (isManager) {
        req = status === 'OPEN' || status === 'QUEUE';
      } else if (status === 'OPEN' || status === 'QUEUE') {
        req = (status === 'QUEUE' && type === 'my-listing') || isMe;
        isMe = status === 'OPEN' && type === 'me';
      }

      if (isMe) this.$live.reqFlagMessages('READ', room);

      if (req) this.$live.requestHistoricRoom(room.roomId);
    },
    exitRoom() {
      this.$parent.chooseRoom(null, false);
      this.resetRecordAudio();
    },
    async delay(timeout) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timeout);
      });
    },
    async startRecordAudio() {
      if (this.recorder !== null || this.audioURL !== null) return;

      this.resetPreviewFile();

      try {
        const recorder = new MicRecorder({ bitRate: 128 });

        recorder
          .start()
          .then(() => {
            this.recorder = recorder;

            if (this.recordInterval) clearInterval(this.recordInterval);

            this.recordTimer = 0;

            this.recordInterval = setInterval(() => {
              this.recordTimer += 1;

              if (this.recordTimer >= this.recordMax) this.finishRecordAudio();
            }, 1000);
          })
          .catch((ee) => {
            console.error('audio:catch', ee);
          });
      } catch (ee) {
        console.error('audio:catch', ee);
      }
    },
    async finishRecordAudio() {
      if (this.recordInterval) clearInterval(this.recordInterval);

      const channel = this.currentChannel;

      if (!channel) return;

      if (this.recorder !== null) {
        this.recordResolving = true;
        this.recorder
          .stop()
          .getMp3()
          .then(async ([buffer, blob]) => {
            console.log('ffmpeg:#1');

            /*
          <i class="fas fa-globe" v-if="currentChannel === 'widget'"></i>
          <i class="fab fa-facebook-messenger" v-else-if="currentChannel === 'messenger'"></i>
          <i class="fab fa-whatsapp" v-else-if="currentChannel === 'whatsapp'"></i>
          <i class="fab fa-google" v-else-if="currentChannel === 'rcs'"></i>
          <i class="fab fa-telegram" v-else-if="currentChannel === 'telegram'"></i>
          <i class="fab fa-instagram" v-else-if="currentChannel === 'instagram'"></i>
          <i class="fas fa-envelope" v-else-if="currentChannel === 'email'"></i>
          <i class="fab fa-google" v-else-if="currentChannel === 'google-business'"></i>
          */

            // Instagram e whatsapp usará m4a (aac)
            // channel === 'messenger'

            this.recorder = null;

            this.finishRecordAudio2(blob);

            // let type = null;
            // let output;
            // let params = [];

            // if (channel === 'instagram') {
            //   type = 'audio/aac';
            //   output = 'm4a';
            //   // type = 'audio/mpeg';
            //   // output = 'mp4';
            // } else if (channel === 'messenger') {
            //   type = 'audio/mpeg';
            //   output = 'mp4';
            // } else if (channel === 'whatsapp') {
            //   type = 'audio/ogg';
            //   output = 'oga';
            //   params = '-c:a libopus -b:a 16k'.split(/\s+/);
            // }

            // if (type) {
            //   const url = URL.createObjectURL(blob);

            //   console.log('$ffmpeg', { url, output, params });

            //   this.$ffmpeg(url, output, params).then(async (data) => {
            //     console.log('ffmpeg:#2', data, data.buffer);
            //     URL.revokeObjectURL(url);
            //     this.finishRecordAudio2(new Blob([data], { type }));
            //   }).catch((err) => {
            //     this.recordResolving = false;

            //     console.error('ffmpeg', err);

            //     this.$toast.show({
            //       title: 'Upload',
            //       type: 'danger',
            //       content: err.toString(),
            //     });
            //   }).finally(() => {
            //     URL.revokeObjectURL(url);
            //   });
            // } else {
            //   // outros canais usarão mp3
            //   if (channel === 'whatsapp') {
            //     blob = blob.slice(0, blob.size, 'audio/mpeg');
            //   }
            //   this.finishRecordAudio2(blob);
            // }
          });
      }
    },
    async finishRecordAudio2(blob) {
      this.audioData = blob;
      this.audioURL = URL.createObjectURL(this.audioData);

      let ref = this.$refs.audioURLRef;

      if (!ref) {
        await this.delay(60);

        ref = this.$refs.audioURLRef;

        this.recordResolving = false;

        if (!ref) return;
      }

      ref.muted = true;
      ref.play();

      await this.delay(250);

      ref.currentTime = 100;
      ref.pause();

      await this.delay(100);

      ref.currentTime = 0;
      ref.muted = false;

      this.recorder = null;
      this.recordResolving = false;
    },
    async resetRecordAudio() {
      console.log('ondataavailable:reset', this.recorder?.state);
      this.recordResolving = false;

      this.finishRecordAudio();

      if (this.audioURL !== null) URL.revokeObjectURL(this.audioURL);

      this.audioData = null;
      this.audioURL = null;
      this.recorder = null;
    },
    async sendRecordAudio() {
      this.finishRecordAudio();

      if (this.audioURL !== null) {
        const data = this.audioData;
        const type = this.audioData.type;

        console.log('sendRecordAudio', this.audioURL, this.audioData);

        let ext = '';

        if (type.indexOf('/mpeg') !== -1) {
          ext = '.mp3';
          // this.audioData = this.audioData.slice(0, this.audioData.size, 'audio/mpeg');
        } else if (type.indexOf('/ogg') !== -1) {
          ext = '.ogg';
        } else if (type.indexOf('/webm') !== -1) {
          ext = '.webm';
        } else if (type.indexOf('/mp3') !== -1) {
          ext = '.mp3';
        } else if (type.indexOf('/aac') !== -1) {
          ext = '.m4a';
        }

        console.log('sendRecordAudio#2', ext, this.audioData);

        this.sendFile(this.audioData, `audio${ext}`);
      }
    },
    requestFile() {
      if (!this.uploading) this.$refs.attachInput.click();
    },
    async previewFileFromClipboard(event) {
      const source = event.clipboardData?.files?.[0];

      if (source.type && source.type.indexOf('image/') === 0) {
        event.preventDefault();
        this.previewFile(source);
      }
    },
    async previewFileFromInput() {
      const source = this.$refs.attachInput.files.length ? this.$refs.attachInput.files[0] : null;

      if (source) this.previewFile(source);
    },
    async previewFile(source) {
      const room = this.$parent.currentRoom;

      console.log('previewFile', source, room);

      if (!source || !room) return;

      const channel = room.channel;

      if (this.$live.validateFile(source, channel)) {
        // this.sendFile();
        const url = URL.createObjectURL(source);

        if (source.type.indexOf('image/') === 0) {
          source = await this.$live.imageToJpeg(url);

          console.info('validateFile:source', source);

          if (!source) {
            this.$toast.show({
              title: 'Upload',
              type: 'danger',
              content: 'Imagem inválida ou danificada',
            });
            return;
          }
        }

        this.beforeUpload = { url, source };
      } else {
        this.$toast.show({
          title: 'Upload',
          type: 'danger',
          content: `Tamanho inválido do arquivo ${(source.size / (1024 * 1024)).toFixed(
            2,
          )} MB para o canal ${channel}`,
        });
        this.resetPreviewFile();
      }
    },
    resetPreviewFile() {
      this.beforeUpload = null;
      this.$refs.attachInput.value = '';
    },
    sendFile(file, fileName) {
      // const file = this.audioData;

      console.log('sendMessage:sendFile#2', this.uploading, file);

      this.resetRecordAudio();

      if (this.uploading) return;

      this.resetPreviewFile();

      this.uploading = true;

      const mime = file.type.replace(/\/x-/, '').replace(/(\s+)?;.*/, '');

      if (!fileName) {
        fileName = String(Date.now());

        switch (mime) {
          case 'image/jpeg':
          case 'image/jpg':
          case 'image/jpe':
            fileName += '.jpg';
            break;
          case 'image/png':
            fileName += '.png';
            break;
          case 'image/gif':
            fileName += '.gif';
            break;
          case 'image/svg':
          case 'image/svg+xml':
          case 'application/svg+xml':
            fileName += '.svg';
            break;
          case 'video/mpeg':
          case 'video/mp4':
            fileName += '.mp4';
            break;
          default:
            console.info('sendFile', mime);
            fileName += file.name.replace(/^.*?\.([^.]+$)/, '.$1');
        }
      }

      console.log('sendFile:', { file, fileName });

      this.reqTriggerScroll();

      const channel = this.currentChannel;
      const requestConvert =
        mime === 'audio/mp3' &&
        ['instagram', 'whatsapp', 'whatsapp-java', 'messenger'].indexOf(channel) !== -1;

      this.$live
        .sendFile(
          this.$parent.currentRoom.roomId,
          file,
          fileName,
          requestConvert,
          this.message,
          channel,
        )
        .then((data) => {
          console.log('sendFile/UPLOAD', data);
        })
        .catch((error) => {
          console.error('sendFile/UPLOAD', error);
          this.$toast.show({
            title: 'Upload',
            type: 'danger',
            content: 'Falha ao enviar o arquivo',
          });
        })
        .finally(() => {
          this.uploading = false;
          console.info('sendFile/UPLOAD', 'finally');
        });
    },
    // sendFile() {
    //   this.$parent.$refs['live-send-file-modal'].show();
    // },
    callLight(message) {
      this.$emit('callLight', message);
    },
    addEmoji(emoji) {
      const roomId = this.$parent.currentRoom.roomId;

      if (!roomId) return;

      if (this.message) {
        this.messages[roomId] += ` ${emoji}`;

        const input = this.$refs.inputMessage;

        if (input) input.scrollTop = input.scrollHeight;
      } else {
        this.messages[roomId] = emoji;
      }

      this.refreshMessage += 1;
    },
    sendMessageTemplate(form) {
      this.messages[this.$parent.currentRoom.roomId] = form.body;
      this.templateName = form.templateName;
      this.sendMessage();
    },
    sendTemplate() {
      this.$root.$emit('show.modal', 'send-m');
    },
    showTemplates() {
      this.$refs['ref-templates'].show();
    },
    sendMessage(event) {
      const message = this.message.trim();
      const room = this.$parent.currentRoom;
      const source = this.beforeUpload?.source;

      if (message.indexOf('/') === 0) return;

      if (message || source) {
        if (event && event.type === 'keydown' && (event.ctrlKey || event.shiftKey)) {
          this.messages[room.roomId] += '\n';
        } else if (this.messageDetails.length <= this.messageDetails.limit) {
          if (source) {
            console.log('sendMessage:sendFile#1', source);
            this.sendFile(source, null);
          } else {
            console.log('sendMessage:this.$live.sendMessage');
            this.$live.sendMessage(room, message);
          }

          this.messages[room.roomId] = '';
          this.refreshMessage += 1;
          this.templateName = null;
          // console.log('Enviado');

          this.beforeSentTyping(false);

          if (this.$refs.inputMessage) this.$refs.inputMessage.focus();
        }
      }

      this.refreshMessage += 1;
    },
    beforeSentTyping(typing) {
      // if (this.timeoutTyping) clearTimeout(this.timeoutTyping);
      // this.timeoutTyping = setTimeout(() => this.sentTyping(typing), 500);
    },
    sentTyping(typing) {
      // console.log('XXXXXXX', this.$live.online, this.$live.id);

      if (this.$parent.currentRoom && this.message !== '' && this.statusTyping !== typing) {
        // console.log('sentTyping', typing, this.$parent.currentRoom);

        this.statusTyping = typing;

        this.$live.emit('typing', {
          typing,
          roomId: this.$parent.currentRoom.roomId,
        });
      }
    },
    reqTriggerScroll() {
      clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        this.triggerScroll();
      }, 100);
    },
    triggerScroll() {
      // console.log('$$$triggerScroll');

      const area = this.$refs.transcript;

      if (area) area.scrollTop = area.scrollHeight - area.clientHeight;
    },
    reqResumeOpen() {
      const room = this.$parent.currentRoom;

      Swal.fire({
        title: 'Sessão',
        text: 'Iniciar uma nova sessão para esse cliente?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (!result.value) return;

        this.$parent.currentRoom = null;
        this.$parent.tabHistoryIndex = 0;

        if (room.fromIdle) {
          this.$live.openIdleNow(room);
        } else {
          this.$live.openNow(room);
        }
      });
    },
    sendResumeSms() {
      const markAsSolved = this.markAsSolvedAfter;
      const room = this.$parent.actualRoom;
      const mobileNumber = room?.contact?.mobileNumber;

      if (!mobileNumber) {
        console.error('sendResumeSms', room);
        return;
      }

      this.resume.sending = 'sending';

      const form = this.resume.sms.form;

      form.agentId = this.$live.myUserId;
      form.clientId = room.clientId;
      form.phone = mobileNumber;

      console.log('sendResumeSms', form);

      axios()
        .post('webchat/resume/sms', form)
        .then((response) => {
          this.resume.sending = 'done';
          this.reqTriggerScroll();

          if (markAsSolved) this.$live.markAsSolved(room);
        })
        .catch((ee) => {
          this.resume.sending = 'none';

          this.$toast.show({
            title: 'Falha ao enviar SMS',
            content: String(ee),
            type: 'danger',
          });
        });
    },
    sendResumeWhatsApp(newSystem) {
      const markAsSolved = this.markAsSolvedAfter;
      const room = this.$parent.actualRoom;
      const mobileNumber = room?.contact?.mobileNumber;

      if (!mobileNumber) {
        console.error('sendResumeWhatsApp', room);
        return;
      }

      this.resume.sending = 'sending';

      let request = null;

      if (newSystem) {
        const template = this.newWhatsAppTemplateSelected;
        const form = {
          button_parameter: null,
          client_number: mobileNumber.replace(/\D+/g, ''),
          header_parameter: null,
          header_type: null,
          language_code: template.language,
          template_name: template.name,
          business_id: template.business_id,
          type: 'template',
        };

        console.info('send whatsapp', { form, template });

        request = axios().post('whatsapp/new/send', form);
      } else {
        const form = this.resume.whatsapp.form;

        form.agentId = this.$live.myUserId;
        form.clientId = room.clientId;
        form.phone = mobileNumber;

        console.log('sendResumeWhatsApp', form);

        request = axios().post('webchat/resume/whatsapp', form);
      }

      request
        .then((response) => {
          this.resume.sending = 'done';
          this.reqTriggerScroll();

          if (markAsSolved) this.$live.markAsSolved(room);
        })
        .catch((ee) => {
          this.resume.sending = 'none';

          this.$toast.show({
            title: 'Falha ao enviar WhatsApp',
            content: String(ee),
            type: 'danger',
          });
        });
    },
    insertTemplate(template) {
      const form = this.resume.whatsapp.form;

      form.template = {};
      form.template.name = template.name;

      template.components.forEach((element) => {
        form.template.type = 'text';

        switch (element.type) {
          case 'HEADER':
          case 'FOOTER':
            form.template.header = element;
            form.template.type = 'rich';

            if (element.type === 'HEADER' && element.format === 'IMAGE') {
              form.template.type = 'image';
            }
            break;
          case 'BODY':
            form.template.body = element.text;
            form.body = element.text;
            break;
          case 'BUTTONS':
            form.template.buttons = element.buttons;
            form.template.type = 'rich';
            break;
          default:
            console.info(element);
        }
      });

      const params = form.template.body.match(/\{\{(.*?)\}\}/g);

      if (params) {
        form.params = params.map((_, index) => ({ index: index + 1, text: '' }));
      } else {
        form.params = null;
      }

      console.log('form.params', form.params);
    },
    createSampleAction() {
      this.createSampleMsgNew = 2;

      this.$live
        .createSampleMessage(
          this.sampleForm.key,
          this.sampleForm.message,
          this.sampleForm.availableTo,
        )
        .then(() => {
          this.toggleCreateSampleMessage(false);
          this.createSampleMsgNew = 0;
        });
    },
    toggleCreateSampleMessage(force) {
      if (typeof force === 'boolean') {
        this.createSampleMsg = force;
      } else {
        this.createSampleMsg = !this.createSampleMsg;
      }

      if (this.createSampleMsg) this.$live.requestSampleMessages();
    },
    chooseSampleMessage(sample) {
      const roomId = this.$parent.currentRoom.roomId;

      if (!roomId) return;

      this.messages[roomId] = sample.message;
      Vue.set(this.messages, roomId, sample.message);

      this.toggleCreateSampleMessage(false);

      console.info('chooseSampleMessage', [roomId, this.message]);

      if (this.$refs.inputMessage) this.$refs.inputMessage.focus();

      this.refreshMessage += 1;
    },
    interactive(content) {
      return content.replace(/([<]span data-interactive="true"[>])/g, `$1${INTERACTIVE_CONTENT}`);
    },
    handleInteractive(event) {
      const target = event.target;

      if (target.matches('[data-interactive="copy"], [data-interactive="copy"] *')) {
        const data = target.closest('[data-interactive="true"]').textContent.trim();

        if (this.$clipboard(data)) {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: 'Copiado',
            type: 'success',
          });
        }
      }
    },
    removeRoomPrefix(roomId) {
      return roomId.indexOf('spy:') === 0 ? roomId.substring(4) : roomId;
    },
    selectWhatsappTemplate(template) {
      if (this.$refs['whatsapp-template-select'].selectedTemplate) {
        this.selectedWhatsappTemplate = this.$refs['whatsapp-template-select'].selectedTemplate;
        this.$refs['whatsapp-templates'].hide();
      } else {
        this.$toast.show({
          title: 'Erro',
          content: 'É necessário adicionar um template',
          type: 'danger',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.whatsapp-meta-component {
  &::v-deep {
    .card {
      margin-bottom: 0 !important;
    }

    .main {
      padding: 0 !important;

      &>.mt-3 {
        margin-top: 0 !important;
      }

      &>.my-4 {
        border: none !important;
        margin: 0 !important;
        margin-bottom: 3px !important;
      }
    }
  }
}

.omni-transcript.today {
  .omni-transcript-header {
    display: none;
  }
}

.omni-transcript {
  &+.omni-transcript.today .omni-transcript-header {
    display: block;
  }
}

.omni-transcript-header {
  position: sticky;
  z-index: 90;
  top: -1rem;
  // left: 50%;
  // top: 0;
  // transform: translateX(-50%);
  text-align: center;
  margin: 0 auto;
  pointer-events: none;

  &>.card {
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 2.5rem !important;
    display: inline-block;
    font-size: 0.72rem;
    font-weight: bold;
    padding: 0.2rem 0.6rem;
  }

  .dot {
    display: inline-block;
    vertical-align: middle;
    border-radius: 0.4rem;
    margin: 0 0.25rem;
    background-color: #752de6;
    width: 0.4rem;
    height: 0.4rem;
  }
}

.material-icons-outlined {
  color: #788db4 !important;
}

.whatsapp-bg {
  background: #efeae2 url('/assets/img/chat-bg.png');
  margin: 1.25rem -1.25rem;
  padding: 1rem;
  pointer-events: none;
}

.whatsapp-message {
  display: inline-block;
  max-width: 65%;
  min-width: 224px;
}

.whatsapp-preview {
  margin: 0 0 0.1rem 0;

  &>p {
    margin: 0;
  }
}

.qt-loader {
  text-align: center;
  padding: 32px;
}

.recording-write {
  text-align: center;

  &::v-deep .spinner-grow {
    margin: 0 0.22em;
  }
}

.live-inner-header {
  border-bottom: thin solid rgba(0, 0, 0, 0.08);

  .live-header-info {
    padding-left: 1rem;
    height: 100%;
  }
}

.live-resume-contact {
  margin-top: 1em;

  &::v-deep>.card>.tabs {
    .tab-content {
      overflow: auto;
      height: 100%;
      max-height: 350px;
    }

    &>.card-header {
      padding-bottom: 0;

      &>.nav {
        margin-bottom: 0;
      }
    }
  }
}

.live-header-contact {
  margin-top: 0.32rem;
  font-size: 0.8rem;
  display: none !important;

  &.current {
    display: flex !important;
  }
}

.live-media-preview {
  background: transparent;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;

  video {
    background: #333;
  }

  &>* {
    pointer-events: none;
    min-height: 16px;
    min-width: 16px;
    display: block;
  }
}

.live-conversation {
  display: flex;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  margin: 10px 0 0 15px;
  flex-direction: column;
  position: relative;
}

.live-old-load {
  padding: 1em;
  text-align: center;
}

.live-transcript {
  position: relative;
  height: calc(100% - 172px);
  overflow: hidden;
  overflow-y: auto;

  &.readonly,
  &.live-transcript-old {
    height: calc(100% - 80px);
    background: url('data:image/svg+xml,%3Csvg width="220px" height="220px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">%3Cg fill="%23eee">%3Cpath fill="none" d="M0 0H24V24H0z"/>%3Cpath d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z"/>%3C/g>%3C/svg>') bottom right no-repeat;
  }
}

.live-transcript-old+.live-transcript {
  height: 0 !important;
  padding: 0 !important;
}

.live-manager-actions {
  position: relative;
  height: 92px;
  text-align: right;

  &.live-resume-actions {
    width: 204px;
    position: absolute;
    bottom: 0;
    right: 0.5em;
    z-index: 1000;
  }

  width: 204px;
  position: absolute;
  bottom: 0;
  right: 0.5em;

  .live-manager-enter {
    color: #fff;
    background-color: #752de6;
    padding: 1em 2em;
    border-radius: 0.2rem;
    border: none;
    font-size: 9pt;
    display: block;
    width: 98%;
    height: 40px;
    white-space: nowrap;

    &~.live-manager-enter {
      margin-top: 0.3em;
    }

    &,
    i,
    .material-icons-outlined {
      vertical-align: middle;
    }

    i,
    .material-icons-outlined {
      font-size: 1.2rem;
      margin-right: 0.5em;
      color: #fff !important;
    }
  }
}

.live-input {
  // display: flex;
  // flex-direction: row;
  // flex-wrap: nowrap;
  // justify-content: space-between;
  // align-content: normal;
  // align-items: center;
  border: thin solid #e5e5e5;
  background: #fff;
  padding: 0 0.5em;
  position: relative;
  z-index: 90;

  .tools-by-channel {
    height: 45px;
  }

  .tools {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: normal;
    align-items: center;
    // border-top: thin solid #eee;
  }

  .material-icons-outlined {
    font-size: 1.3rem;
  }

  // @media (max-width: 800px) {
  //   width: 100%;
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  // }

  &>* {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 40px;
    align-self: auto;
    order: 0;
    height: 45px;

    @media (max-width: 480px) {
      flex-basis: 45px;
    }
  }

  .live-file-preview {
    position: absolute;
    z-index: 90;
    left: -0.6rem;
    bottom: 1rem;
    width: calc(100% + 1.2em);
    height: 60vh;
    max-height: 640px;
    // margin: 0;
    // border: 0;
    // border-radius: 0;
    // background: #fff;
    // border: 0 solid #e8e4e4;
    // border-top-width: thin;
    // box-shadow: 0 -31px 31px -22px rgba(0,0,0,.2);

    aside {
      min-width: 200px;

      img,
      audio,
      video {
        display: block;
        margin: 0 auto;
      }
    }

    &.live-record-audio {
      z-index: 91;
      bottom: -3rem;

      .record {
        text-align: center;

        .timer {
          font-size: 9pt;
        }

        .audio-finish {
          margin: 10px 0;
          border-radius: 48px;
          background: #f00;
          border: none;
          height: 92px;
          width: 92px;
          font-size: 32pt;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.02), 0 0 0 0 rgba(0, 0, 0, 0.06);
          animation-duration: 0.4s;
          animation-name: audioWave;
          animation-iteration-count: infinite;
          animation-direction: alternate;

          i,
          & {
            color: #fff !important;
          }

          @keyframes audioWave {
            from {
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.02), 0 0 0 0 rgba(0, 0, 0, 0.06);
            }

            to {
              box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2), 0 0 0 4px rgba(0, 0, 0, 0.2);
            }
          }
        }

        footer {
          font-size: 7pt;
        }

        .row {
          padding: 20px 0;
        }

        .row>div {
          padding: 0;
        }

        .audio-cancel,
        .audio-send {
          width: 100%;
        }
      }
    }

    .card-header {
      height: 72px;

      button {
        background: transparent;
        border: none;
        padding: 0;

        i {
          font-size: 16pt;
        }
      }
    }

    .card-body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 72px);
      position: relative;

      aside {

        img,
        object,
        video {
          position: relative;
          border: thin solid #e8e4e4;
          object-fit: contain;
          height: 100%;
          max-height: 240px;

          @media (max-height: 500px) {
            max-height: 150px;
          }

          @media (max-height: 420px) {
            max-height: 110px;
          }
        }

        .d-flex {

          @media (max-height: 420px),
          (max-width: 240px) {
            font-size: 8pt;
          }
        }
      }
    }
  }

  .live-file-preview-meta {
    font-size: 9pt;
  }
}

.live-input-out {
  flex-grow: 1;
  flex-basis: auto;
  // height: 52px;
  // padding: 0 12px;
  // margin: 0 12px;
  height: auto !important;
}

.live-input {
  // background: #f7f7f7;
  background: #fff;
  // margin: 0 1.2em;
  margin: 0;
  border-radius: 0.2rem;
  margin-top: 20px;

  ::v-deep button>span,
  ::v-deep button>i {
    display: block;
  }

  .window-create-sample-msg {
    position: absolute;
    left: 0.32rem;
    bottom: 0.82rem;
    background: #fff;
    width: 420px;

    .window-create-sample-close {
      border: none;
      border-radius: 20px;
      background: #000;
      position: absolute;
      right: -15px;
      top: -15px;
      width: 32px;
      height: 32px;

      i {
        color: #fff;
      }
    }

    h1 {
      font-size: 12pt;
    }

    &>.relative {
      position: relative;
      padding: 0.7rem;
    }

    .qt-block-ui {
      position: absolute !important;
    }
  }

  .btn-create-sample-msg {
    background: transparent !important;
    border: none !important;
    height: auto !important;
    // position: absolute;
    // right: 0;
    // top: 0;
    padding: 0.62rem 0.2rem;
    width: 100% !important;
    height: 100% !important;

    // i {
    //   color: #fff;
    // }

    &+textarea {
      width: calc(100% - 40px - 1.5rem);
    }
  }

  .sample-messages {
    height: 262px;
    overflow: auto;
    overflow-x: hidden;

    .list-group-item {
      text-align: left;

      &>div {
        max-height: 52px;
        overflow: hidden;
      }
    }
  }

  .live-input-in {
    border-bottom: thin solid #edeef0;
    position: relative;
    margin: 0 12px;

    .live-input-textarea {
      // width: calc(100% - 40px);
      width: 100%;
      border: none;
      background: transparent;
      // background: #fc0;
      margin: 0;
      resize: none;

      // padding: .5em 0 0 0;
      // max-height: 6em;

      padding: 1.4em 0 0 0;
      max-height: 12.2em;

      // font-size: .92rem;

      &[readonly] {
        color: #cdcdcd;
      }
    }

    .window-listing-sample-msgs {
      position: absolute;
      left: 1.2rem;
      bottom: 0.82rem;
      background: #fff;
      max-width: 320px;
      min-width: 100px;
      overflow: auto;
      overflow-x: hidden;
      max-height: 300px;
      display: none;

      &.show {
        display: block;
      }

      .notfound {
        font-size: 10pt;
      }
    }

    .list-group-item {
      padding: 0;
      font-size: 8pt;
      text-align: left;

      &>strong,
      & .sample-item,
      &>div>strong {
        padding: 0.35rem;
        display: block;
      }

      .sample-dones {
        position: relative;
        padding: 0.35rem;
        padding-top: 0;

        &::after {
          position: absolute;
          left: -5%;
          bottom: 0;
          content: '';
          height: 10px;
          width: 110%;
          box-shadow: 5px -5px 6px 0 rgba(255, 255, 255, 0.5) inset;
        }
      }

      .sample-item {
        width: 92px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #c0c0c0;
      }
    }

    .filter-msgs {
      .input-group-prepend {
        &>.input-group-text {
          background: transparent;
        }
      }
    }

    .new-sample {
      margin: 0 !important;

      * {
        font-size: 9pt !important;
      }

      textarea {
        min-height: 140px;
      }

      &> :first-child {
        padding: 0;
      }

      &> :last-child {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
      }

      .form-check-label {
        width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .form-check {

        &>input,
        &>label {
          vertical-align: middle;
        }

        &>label {
          margin-top: 0.1rem;
        }
      }

      .form-footer {
        &> :first-child {
          padding-right: 0.3rem !important;
        }

        &> :last-child {
          padding-left: 0.3rem !important;
        }
      }
    }
  }

  ::v-deep .emojis .popup-box-container button>span {
    display: block;
    height: 25px;
  }

  .live-templates,
  ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    width: 40px;
    height: 45px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}

/*
  .live-enter {
    float: right;
    height: 62px;
    width: 62px;
  }

  ::v-deep .emojis {
    float: left;
  }

  .live-templates, ::v-deep .emojis .popup-box-container button {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    float: left;
    width: 48px;
    height: 62px;

    i {
      font-size: 18pt;
      color: #c1c7cd;
    }

    @media (max-width: 480px) {
      width: 22px;

      i {
        font-size: 12pt;
      }
    }
  }
}*/

.live-inner-header {
  border-bottom-width: thin;
  position: relative;
  height: 72px;
  min-height: 72px;

  .material-icons-outlined {
    color: #752de6 !important;
    font-size: 1.2rem;
  }

  .live-status {
    font-size: 7pt;
    font-weight: bold;
    vertical-align: middle;
  }

  .live-back {
    font-size: 14pt;
    float: left;
    width: 54px;
    height: 100%;
    padding-top: 10px;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
      background-color: #fcfcfc;
    }

    .material-icons-outlined {
      font-size: 1.8rem;
    }

    @media (min-width: 801px) {
      // display: none !important;
    }
  }

  &>.live-photo {
    margin: 12px 10px 0 0;
    float: left;
    height: 48px;
    width: 48px;
    position: relative;
    border-radius: 0;
    background: transparent;

    .live-channel {
      position: absolute;
      right: -2px;
      top: -2px;
      font-size: 0.72rem;
      height: 16px;
      width: 16px;
      background: #fff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: #752de6;
      }
    }

    button {
      background: #dfdfdf;
      border: none;
      border-radius: 48px;
      float: left;
      height: 100%;
      width: 100%;
      font-size: 12pt;
      padding: 0;
      overflow: hidden;
      border-radius: 24px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .live-header-info {
    padding-top: 8px;
    width: calc(100% - 410px);
    font-size: 12pt;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    float: left;
  }

  .live-header-name {
    font-weight: bold;

    .sub {
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.is-manager {
  .live-header-info {
    width: calc(100% - 260px);
  }
}

.live-history-header {
  text-align: right;
  padding: 0.5em;
  border: solid #f7d85c;
  border-width: thin 0;
  background: #ffefad;
  font-size: 9pt;

  button {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    border: none;
    padding: 2px 10px;
    margin-left: 6px;
    transition: background-color 0.3s ease;
    vertical-align: middle;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.live-header-tools {
  padding: 21px 5px 0 5px;
  float: right;
  // width: 135px;
  width: 200px;
  text-align: right;

  @media (max-width: 920px) {
    display: none;
  }

  &::v-deep button:not(.btn-common),
  .menu-item {
    border: none !important;
    padding: 0 !important;
    border-radius: 50%;
    height: 36px !important;
    width: 36px !important;
    margin-left: 5px;
    vertical-align: middle;
  }

  &::v-deep .dropdown-menu {
    /*
    right: auto !important;
    left: 0 !important;
    top: 0 !important;
    */
    right: 0 !important;
    left: auto !important;
    top: 36px !important;
    transform: none !important;
  }

  .menu-item {
    display: inline-block;

    &::v-deep .dropdown {
      z-index: 1000;

      &,
      button:not(.btn-common) {
        color: #788db4 !important;
        margin: 0 !important;
      }

      button:not(.btn-common) {
        box-shadow: none !important;
        background: transparent !important;
        border: none;
        padding: 8px;
        width: 100%;

        &::after {
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          box-shadow: none !important;
          background: #fafafa !important;
        }
      }
    }
  }

  button:not(.btn-common)::v-deep {
    box-shadow: none !important;
    background: transparent !important;

    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      background: #fafafa !important;
    }
  }
}

.live-header-tools-m {
  display: none;
  width: 20%;
  float: right;
  margin-top: 22px;
  text-align: center;

  @media (max-width: 920px) {
    display: block;
  }
}

.live-inner-input {
  border-top-width: thin;
}

.live-transcript {
  padding: 1.5em;
}

.live-message {
  font-size: 10pt;
  min-width: 240px;
  margin-bottom: 35px;

  .live-agent {
    position: absolute;
    right: -10px;
    top: -10px;
    color: #fff;
    font-size: 7pt;
    text-transform: uppercase;
    padding: 0.5em !important;
    line-height: 1 !important;

    &.finished {
      background: #f00 !important;
    }
  }

  .live-message-box {
    position: relative;
    min-width: 200px;
    max-width: 60%;
    float: left;
    z-index: 1;

    .icon-fail {
      font-size: 8pt;
      color: #fff;
    }

    .live-message-source {
      display: none;
      position: absolute;
      right: 0;
      top: 0;
      padding: 4px 2px 20px 2px;
      font-size: 10pt;

      i {
        vertical-align: middle;
        margin-right: 2px;
      }
    }
  }

  .live-message-inner::before {
    background: #d6dadd;
    position: absolute;
    bottom: 10px;
    left: -5px;
    z-index: -1;
    content: '';
    height: 8px;
    width: 8px;
    transform: rotate(45deg);
  }

  &.me,
  &.automatic {
    .live-message-inner::before {
      left: auto;
      right: -5px;
    }
  }

  .live-message-content {
    overflow: auto;
    overflow-wrap: break-word;
    // word-break: break-all;
    color: #000;
    font-weight: 500;
  }

  // &.me .live-message-content {
  //   color: #fff;
  // }

  .live-message-content::v-deep {
    code {
      overflow-wrap: break-word;
      white-space: pre-wrap;
      color: inherit;
    }

    [data-interactive] {
      display: inline-block;
      position: relative;
      font-weight: 400;

      &>div {
        position: absolute;
        right: -45%;
        top: -82%;
        opacity: 0;
        transition: 0.2s all ease-out;

        &>button {
          box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1);
          border-radius: 0.1rem;
          border: thin solid #c1c1c1;
          background: #fff;
          color: #c1c1c1;
        }
      }

      &:hover>div {
        opacity: 1;
      }
    }
  }

  .live-message-inner {
    position: relative;
    border: 2px solid #d6dadd !important;
    background: #f5f5f5;
    // padding: 14px 14px 32px 14px;
    padding: 14px;
    border-radius: 0.24em;
    letter-spacing: normal !important;

    .live-message-opts {
      position: absolute;
      right: 10px;
      top: 10px;

      &::v-deep .dropdown-toggle {
        visibility: hidden !important;
        background: transparent !important;
        padding: 0.1em 0.4em;
        height: auto;
        line-height: 1;
      }
    }

    &:hover .live-message-opts {
      &::v-deep .dropdown-toggle {
        visibility: visible !important;
        background: transparent !important;
        padding: 0.1em 0.4em;
        height: auto;
        line-height: 1;
      }
    }

    &>picture {
      position: absolute;
      right: -10px;
      top: -10px;
      color: #fff;
      background: #cfcfcf;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 4px;
      pointer-events: none;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &>strong {
      display: none;
      // display: block;
      padding-bottom: 0.4em;
    }

    .live-message-details {
      position: absolute;
      right: 0;
      bottom: -25px;
      font-size: 80%;
    }

    .live-message-time {
      opacity: 0.7;
      margin-right: 5px;
    }
  }

  // &:not(.me) .live-message-inner {
  //   border-top-left-radius: 0;
  // }

  &.another-agent .live-message-inner {
    // background: #f5eaf9 !important;
    border-color: #f5eaf9 !important;

    &::before {
      background: #f5eaf9 !important;
    }
  }

  &.place-offline .live-message-inner {
    // background: #f5eaf9 !important;
    border-color: #f5eaf9 !important;

    &::before {
      background: #f5eaf9 !important;
    }
  }

  &.automatic .live-message-inner {
    // background: #ffedc7 !important;
    border-color: #ffedc7 !important;

    &::before {
      background: #ffedc7 !important;
    }
  }

  &.me .live-message-inner {
    // background: #752de6 !important;
    // border: none !important;
    // color: #fff;
    border-color: #752de6 !important;

    &::before {
      background: #752de6 !important;
    }
  }

  &.fail {
    .live-message-inner {
      border: none !important;
      background: var(--danger) !important;
      color: #fff;
    }
  }

  &.deleted {
    .live-message-content {
      color: #cfcfcf !important;

      .live-message-text {
        font-style: italic;
      }
    }
  }

  &.me .live-message-box,
  &.automatic .live-message-box {
    float: right;

    .live-message-source {
      text-align: right;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  .live-message-attachments {

    video,
    img,
    audio {
      max-height: 320px;
      max-width: 100%;
    }
  }

  &.upload {
    .live-message-box {
      .live-message-inner {
        padding-bottom: 14px;
        white-space: nowrap;

        >span {
          margin-right: 4px;
        }
      }

      text-align: center;
      min-width: 1px;
      max-width: 60%;
    }
  }
}

.toggle-meta {
  @media (min-width: 1231px) {
    display: none;
  }
}

.live-input-count {
  position: absolute;
  right: 0;
  bottom: -17px;
  font-size: 7pt;

  .exceed {
    font-weight: bold;
    color: #f00;
  }
}

.live-input-btn {
  cursor: pointer;
  padding: 0;
  float: right;
  width: 40px;
  margin-top: 2px;
  height: 40px;
  border: none;
  background: transparent;
  font-size: 12pt;
  transition: 0.3s background ease;
  outline: none;
  background: transparent;
  border-radius: 0.15rem;

  &:hover {
    background-color: #f4f4f4;
  }

  &.live-enter,
  &.live-speak {
    .material-icons-outlined {
      color: #752de6 !important;
    }
  }

  /*
  &:enabled, .live-speak {
    background: #752de6;
  }
  */
}

.live-input-btn,
.btn-create-sample-msg,
.live-header-tools::v-deep button {
  &:not(:enabled) {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

/*
#live-attach {
  float: left;
}
*/

#live-attach-input {
  display: none;
}

.live-external {
  position: relative;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  display: block;
  color: #fff;
  overflow: hidden;

  &>i {
    color: inherit;
    text-align: center;
    vertical-align: middle;
    width: 62px;
    font-size: 42pt;
    padding: 8px;
    display: inline-block;
  }

  .live-attach-name {
    vertical-align: middle;
    padding: 3px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 70px);
    display: inline-block;
    text-align: right;
    direction: rtl;
  }

  .live-attach-type {
    background: rgba(0, 0, 0, 0.15);
    padding: 3px 6px;
    clear: both;
  }
}

#live-attach ::v-deep {
  button {
    border: none !important;
    background: transparent !important;
    width: 40px !important;
    padding: 0 !important;
    height: 45px !important;
    font-size: 14pt;
    box-shadow: none !important;

    &:hover,
    &:active {
      background: #fcfcfc !important;
    }
  }

  ul {
    top: auto !important;
    left: 0 !important;
    right: auto !important;
    bottom: 50px !important;
  }

  .dropdown-toggle::after {
    display: none;
  }

  @media (max-width: 480px) {
    button {
      height: 58px !important;
      font-size: 14pt;
      padding: 0 15px;
    }

    ul {
      bottom: 38px !important;
    }
  }
}

.icon-live {
  font-size: 18pt;
  color: #c1c7cd;
  float: left;
  margin-top: 18px;
}

.fab-item-image {
  text-align: center !important;
}

.fab-item-image i {
  display: block;
  color: rgb(140, 140, 140);
  text-align: center;
  margin-top: 8px;
}

.up-spinner {
  animation: rotation 0.32s linear infinite;
  transform-origin: 0.72rem 0.68rem;
  height: 1.4rem;
  width: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.live-conversation::v-deep {

  .cke_top,
  .cke_inner {
    background: transparent !important;
  }

  .cke {
    border: none !important;
  }
}
</style>
