<template>
  <span>
    <SelectComponent label="Remetente" v-model="selectedNumber" :options="numbers" text-field="label" value-field=""
      @input="changeNumber"></SelectComponent>
    <SelectComponent v-if="selectedNumber" label="Template" v-model="selectedTemplate" :options="templates"
      text-field="name" value-field="" :loading="fetchingTemplates"></SelectComponent>
    <TemplatePreview v-if="selectedTemplate" :template="selectedTemplate"></TemplatePreview>
  </span>
</template>

<script>
import SelectComponent from '@/components/form/SelectComponent.vue';
import TemplatePreview from '@/components/whatsappj/TemplatePreview.vue';
import defaultImage from '@/assets/img/card_header.png';

export default {
  name: 'TemplateSelectComponent',
  components: {
    TemplatePreview,
    SelectComponent,
  },
  props: {},
  data() {
    return {
      fetchingTemplates: false,
      numbers: [],
      templates: [],
      selectedNumber: '',
      selectedTemplate: '',
    };
  },
  created() {
    this.fetchNumbers();
  },
  methods: {
    fetchNumbers() {
      this.$store.dispatch('fetchWhatsappNumbers').then(
        (numbers) => {
          this.numbers = numbers.map((number) => ({
            ...number,
            label: `${number.phone_number} - ${number.name}`,
          }));
        },
      );
    },
    fetchTemplates() {
      this.fetchingTemplates = true;
      this.$store.dispatch('fetchWhatsappTemplates', {
        status: 'APPROVED',
        number: this.selectedNumber.whatsapp_identification ?? this.selectedNumber.phone_number,
      }).then(
        (templates) => {
          templates.forEach((template) => {
            if (template.header) {
              if (template.header.type === 'IMAGE') {
                if (!template.header.example_parameter) {
                  template.header.example_parameter = window.location.origin + defaultImage;
                }
              } else if (!template.header.example_parameter) {
                template.header.example_parameter = '';
              }
            }
          });
          this.templates = templates;
        },
      ).finally(
        () => {
          this.fetchingTemplates = false;
        },
      );
    },
    changeNumber() {
      this.selectedTemplate = null;
      if (this.selectedNumber) {
        this.fetchTemplates();
      }
      this.$emit('changeNumber', this.selectedNumber.whatsapp_identification ?? this.selectedNumber.phone_number);
    },
  },
};
</script>
